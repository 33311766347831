import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import COM_FileUpload from "../Component/COM_FileUpload";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog({
  setAC_SelectedData_Item,
  C_Title,
}) {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  //
  const [DB_ItemList, setDB_ItemList] = useState([]);
  const get_DB_Item_List = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__item__all`)
      .then((res) => {
        setDB_ItemList(res.data);
        console.log("get_DB_Item_List", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const add_DB_Item_List = (
    _item_name,
    _item_desc,
    _item_price_cost,
    _item_price_srp,
    _item_gen_by,
    _item_gen_by_uid,
    _item_gen_by_pic
  ) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/add_vlerp__item`, {
        item_name: _item_name,
        item_desc: _item_desc,
        item_price_cost: _item_price_cost,
        item_price_srp: _item_price_srp,
        item_gen_by: _item_gen_by,
        item_gen_by_uid: _item_gen_by_uid,
        item_gen_by_pic: _item_gen_by_pic,
      })
      .then((res) => {
        setDB_ItemList(res.data);
        console.log("add_DB_Item_List", res.data);
        console.log("last insert id", res.data[2][0].last_id);
        setNewItem_Id(res.data[2][0].last_id);

        if (res.data[1].affectedRows > 0) {
          alert("New Item Add Successfully ! ");

          setTimeout(() => {
            get_DB_Item_List();
            // handleClose();
          }, 2000);
        } else {
          alert("Failed to Add New Item, Please contact Admin !");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // ------------------------------------------------- etc

  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      item_name: "",
      item_desc: "",
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    item_name: "",
    item_desc: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      referer_id: selected_RefererID.current,
      item_name: dialogValue.item_name,
      item_desc: dialogValue.item_desc,
    });

    // // update parent
    setAC_SelectedData_Item({
      item_name: dialogValue.item_name,
      item_desc: dialogValue.item_desc,
    });

    // add to db
    add_DB_Item_List(
      dialogValue.item_name,
      dialogValue.item_desc,
      dialogValue.item_price_cost,
      dialogValue.item_price_srp,
      contextValue.current.firebase_userInfo.displayName,
      contextValue.current.firebase_userInfo.uid,
      contextValue.current.firebase_userInfo.providerData[0]?.photoURL
    );
  };

  // ------------------------------------------------- Global Variable
  const selected_RefererID = useRef("NA");

  const [NewItem_Id, setNewItem_Id] = useState("");

  // ------------------------------------------------- useEffect

  useEffect(() => {
    get_DB_Item_List();
  }, []);

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // Press Enter
            // timeout to avoid instant validation of the dialog's form.

            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                item_name: newValue,
                item_desc: "",
              });
              setAC_SelectedData_Item({
                item_name: newValue,
                item_desc: "",
              });
            });
          } else if (newValue && newValue.inputValue) {
            // new value
            toggleOpen(true);
            setDialogValue({
              item_name: newValue.inputValue,
              item_desc: "",
            });
            setAC_SelectedData_Item({
              item_name: newValue.inputValue,
              item_desc: "",
            });
          } else {
            // etc
            if (newValue == null) {
              // fix reset text cause crash
              setAC_SelectedData_Item([]); // because parent is array
              setValue(newValue);
            } else {
              setAC_SelectedData_Item(newValue);
              setValue(newValue);
            }
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              item_name: `Add "${params?.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={DB_ItemList}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          // console.log("option", option.id);
          selected_RefererID.current = option.id;
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.id == undefined
            ? option.item_name
            : "(" + option.id + ") " + option.item_name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props}>
            {option.inputValue == undefined ? (
              <div className="global_grid_container_custom002">
                <div>
                  <img
                    style={{ width: "150px" }}
                    src={`${REACT_APP_ECLIPSE_API}/uploads/vlerp/product/${option?.id}__product001.jpg`}
                  />
                </div>
                <div className="global_padding_left">
                  {console.log("options2", option.inputValue)}
                  <h4>{`(Item ID : ${option?.id}) ${option?.item_name}`}</h4>
                  <div>{`Desc : ${option?.item_desc?.substring(
                    0,
                    200
                  )}...`}</div>
                  <div className="global_grid_2column_container_5050">
                    <h4>{`Cost : ${option?.item_price_cost}`}</h4>
                    <h4>{`SRP : ${option?.item_price_srp}`}</h4>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <h4>{`${
                  option.inputValue == undefined
                    ? `(Item ID : ${option?.id})`
                    : ""
                } ${option?.item_name}`}</h4>
              </div>
            )}
          </li>
        )}
        sx={{ width: "100%" }}
        freeSolo
        renderInput={(params) => <TextField {...params} label={C_Title} />}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        // fullScreen
        fullWidth={true}
        maxWidth="xl"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a New Item</DialogTitle>
          <DialogContent>
            <DialogContentText>..</DialogContentText>

            {NewItem_Id == "" ? (
              <>
                <div className="global_flex_column_cc gap_50">
                  <TextField
                    fullWidth
                    autoFocus
                    margin="dense"
                    id="name"
                    value={dialogValue.item_name}
                    onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        item_name: event.target.value,
                      })
                    }
                    placeholder="Item Name"
                    type="text"
                    variant="standard"
                  />
                  <TextField
                    fullWidth
                    margin="dense"
                    id="name"
                    value={dialogValue.item_desc}
                    onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        item_desc: event.target.value,
                      })
                    }
                    placeholder="Item Desc"
                    type="text"
                    variant="standard"
                  />
                  <TextField
                    fullWidth
                    margin="dense"
                    id="name"
                    value={dialogValue.item_price_cost}
                    onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        item_price_cost: event.target.value,
                      })
                    }
                    placeholder="Item Cost"
                    type="text"
                    variant="standard"
                  />
                  <TextField
                    fullWidth
                    margin="dense"
                    id="name"
                    value={dialogValue.item_price_srp}
                    onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        item_price_srp: event.target.value,
                      })
                    }
                    placeholder="Item SRP"
                    type="text"
                    variant="standard"
                  />
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  // color="error"
                  type="submit"
                >
                  Add New Item
                </Button>
              </>
            ) : (
              <>
                <div className="global_grid_2column_container_5050">
                  <COM_FileUpload
                    uploadAllow={true}
                    maxSizeMB="0.5"
                    maxWidthOrHeight="1024"
                    buttonName_PostFix={"PHOTO 1 ( Jpg Only ) "}
                    serverDirPath={"uploads/vlerp/product"}
                    uploadFileName={NewItem_Id + "__product001"}
                    displayImgHeight="200px"
                    fileTpe="jpg"
                  />
                  <COM_FileUpload
                    uploadAllow={true}
                    maxSizeMB="0.5"
                    maxWidthOrHeight="1024"
                    buttonName_PostFix={"PHOTO 1 ( Jpg Only ) "}
                    serverDirPath={"uploads/vlerp/product"}
                    uploadFileName={NewItem_Id + "__product002"}
                    displayImgHeight="200px"
                    fileTpe="jpg"
                  />
                </div>

                <br />
                <br />
                <br />
                <br />
                <Button fullWidth variant="contained" onClick={handleClose}>
                  Complete
                </Button>
              </>
            )}
          </DialogContent>
          <DialogActions></DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const sampleData = [
  { title: "The Shawshank Redemption", item_desc: 1994 },
  { title: "The Godfather", item_desc: 1972 },
];
