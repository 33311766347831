import React, { Component, useState, useReducer, useEffect } from "react";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Autocomplete,
} from "@mui/material";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function COM_ItemCart({ p_data, setParent_ItemCart }) {
  const [item, setItem] = useState();
  const [carts, dispatch] = useReducer(reducer, []);
  function reducer(_carts, _action) {
    if (_action.type === "add-todo") {
      let result;
      result = [..._carts, newTodo(_action.payload.item)];
      console.log("result - add-todo", result);
      setParent_ItemCart(result);
      return result;
    } else if (_action.type === "delete-todo") {
      let result;
      result = _carts.filter((todo) => todo.id !== _action.payload.id);
      console.log("result - delete-todo", result);
      setParent_ItemCart(result);
      return result;
    } else {
      console.log("result - etc", _carts);
      setParent_ItemCart(_carts);
      return _carts;
    }
  }

  function newTodo(_item) {
    return { id: Date.now(), item: _item, complete: false };
  }

  // add item
  const addItem = (_item) => {
    dispatch({ type: "add-todo", payload: { item: _item } });
    setItem("");
  };

  return (
    <>
      <div
        className="btn_normal"
        onClick={() => {
          // addItem({ item_id: 12, item_name: "maxsun", selected_ItemQty: 55 });
          addItem({
            item_id: p_data.item_id,
            item_name: p_data.item_name,

            item_desc: p_data.item_desc,
            item_price_cost: p_data.item_price_cost,
            item_price_srp: p_data.item_price_srp,
            item_gen_by: p_data.item_gen_by,
            item_gen_dt: p_data.item_gen_dt,

            selected_Item_PricePerUnit: p_data.selected_Item_PricePerUnit,
            selected_Item_MinOrderQty: p_data.selected_Item_MinOrderQty,
          });
        }}
      >
        {` Add to List (${carts.length} item(s))`}
      </div>
      {carts.map((carts) => {
        return (
          <Cart_Item
            key={carts.id}
            carts={carts}
            dispatch={dispatch}
            setParent_ItemCart={setParent_ItemCart}
          />
        );
      })}
    </>
  );
}

// child component
function Cart_Item({ carts, dispatch }) {
  return (
    <div className="">
      <div className="global_cart_container global_grid_container_custom003 ">
        <div>
          <img
            style={{ width: "150px" }}
            src={`${REACT_APP_ECLIPSE_API}/uploads/vlerp/product/${carts.item.item_id}__product001.jpg`}
          />
        </div>
        <div>
          <b>{`[${carts.item.item_id}] ${carts.item.item_name}`}</b>
          <div>{carts.item.item_desc}</div>
          <br />
          <div className="global_grid_2column_container_5050">
            <b>{`Cost : RM ${carts.item.selected_Item_PricePerUnit}`}</b>
            <b>{`SRP : RM ${carts.item.selected_Item_MinOrderQty}`}</b>
          </div>
        </div>

        <button
          className="btn_normal"
          onClick={() =>
            dispatch({ type: "delete-todo", payload: { id: carts.id } })
          }
        >
          Remove
        </button>
      </div>
    </div>
  );
}
