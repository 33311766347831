import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import { CheckboxGroup, Checkbox, RadioGroup, Radio } from "@mantine/core";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";
import COM_NavBar_Order from "../Component/COM_NavBar_Order";
import COM_NavBar_Report from "../Component/COM_NavBar_Report";

import COM_DatetimeSelector_Custom from "../Component/COM_DatetimeSelector_Custom";

import COM_DateMANTINE from "../Component/COM_DateMANTINE";
import COM_TimeMANTINE from "../Component/COM_TimeMANTINE";

import { UserContext } from "../UserContext";

import Print_Report_Sales_Custom_COM from "../Print/Print_Report_Sales_Custom_COM";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Print_Report_Sales_Monthly() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  //
  const _filter_state = useRef(false);
  const _filter_city = useRef(false);
  const _filter_referrer = useRef(false);
  const _filter_item = useRef(false);

  const RB_Option_State = useRef([]);
  const RB_Option_City = useRef([]);
  const RB_Option_Referrer = useRef([]);

  const DB_Data = useRef([]);
  const get_Data = () => {
    let selected_DateStart = `20${selected_Year_Range[0]}-${selected_Month_Start}-01 00:00:00`;
    let selected_DateEnd = `20${selected_Year_Range[1]}-${selected_Month_End}-31 23:59:59`;

    console.log("### selected_DateStart", selected_DateStart);
    console.log("### selected_DateEnd", selected_DateEnd);
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_vlerp__order_log__salesreport_custom1`,
        {
          // date_from:
          //   moment(RC_DateValue[0]).format("YYYY-MM-DD") +
          //   " " +
          //   moment(timeValue_Start).format("HH:mm:ss"), // "2022-04-01 00:00:00",
          // date_until:
          //   moment(RC_DateValue[1]).format("YYYY-MM-DD") +
          //   " " +
          //   moment(timeValue_End).format("HH:mm:ss"), //"2022-04-21 23:59:59",
          date_from: selected_DateStart,
          date_until: selected_DateEnd,
          filter_state: _filter_state.current,
          filter_city: _filter_city.current,
          filter_referrer: _filter_referrer.current,
          filter_item: _filter_item.current,
        }
      )
      .then((res) => {
        DB_Data.current = res.data;
        console.log("### get_Data", res.data);

        // Manage -  RB Option -----------------------------
        // state
        let tempState = res.data?.map((x) => {
          return x?.client_state;
        });
        RB_Option_State.current = [...new Set(tempState)];
        //  array_state
        console.log("### RB_Option_State", RB_Option_State.current);

        // city
        let tempCity1 = res.data.filter((x) => {
          return x.client_state == filterOption_State.current;
        });
        let tempCity2 = tempCity1?.map((x) => {
          return x?.client_city;
        });
        RB_Option_City.current = [...new Set(tempCity2)];
        //  array_state
        console.log("### RB_Option_City", RB_Option_City.current);

        // referrer
        let tempReferrer1 = res.data.filter((x) => {
          return x.client_state == filterOption_State.current;
        });
        console.log("### tempReferrer1", tempReferrer1);

        let tempReferrer2 = tempReferrer1.filter((x) => {
          return x.client_city == filterOption_City.current;
        });
        console.log("### tempReferrer2", tempReferrer2);

        let tempReferrer3 = tempReferrer2?.map((x) => {
          return x?.referer_id;
        });
        console.log("### tempReferrer3", tempReferrer3);

        RB_Option_Referrer.current = [...new Set(tempReferrer3)];
        //  array_state
        console.log("### RB_Option_Referrer", RB_Option_Referrer.current);

        // Mange Filter - display data ---------------------
        let result = DB_Data.current.filter((x) => {
          if (
            filterOption_State.current != "" &&
            filterOption_City.current == "" &&
            filterOption_Referrer.current == "" &&
            filterOption_Item.current == ""
          ) {
            return x.client_state == filterOption_State.current;
          } else if (
            filterOption_State.current != "" &&
            filterOption_City.current != "" &&
            filterOption_Referrer.current == "" &&
            filterOption_Item.current == ""
          ) {
            return (
              x.client_state == filterOption_State.current &&
              x.client_city == filterOption_City.current
            ); //&& x.client_state == filterOption_State;
          } else if (
            filterOption_State.current != "" &&
            filterOption_City.current != "" &&
            filterOption_Referrer.current != "" &&
            filterOption_Item.current == ""
          ) {
            return (
              x.client_state == filterOption_State.current &&
              x.client_city == filterOption_City.current &&
              x.referer_id == filterOption_Referrer.current
            ); //&& x.client_state == filterOption_State;
          } else {
            return true;
          }
          //  else if (
          //   filterOption_State.current != "" &&
          //   filterOption_City.current != "" &&
          //   filterOption_Referrer.current != "" &&
          //   filterOption_Item.current != ""
          // ) {
          //   return x.item_id == filterOption_Item.current; //&& x.client_state == filterOption_State;
          // }
        });

        setDB_Data_Filtered(result);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const [DB_Data_Filtered, setDB_Data_Filtered] = useState([]);

  //---------------------------------------- mantine time picker
  const [timeValue_Start, setTimeValue_Start] = useState(
    new Date("1990-01-01 00:00:00")
  );
  const [timeValue_End, setTimeValue_End] = useState(
    new Date("1990-01-01 23:59:59")
  );

  //---------------------------------------- mantine date picker
  // - Range DateValue
  const [RC_DateValue, setRC_DateValue] = useState([
    new Date(moment().add({ days: 0, weeks: -144 }).toISOString()),
    new Date(moment().add({ days: 1, weeks: 0 }).toISOString()),
  ]);

  //  - Normal DateValue
  const [NC_DateValue, setNC_DateValue] = useState(
    new Date(moment().add({ days: 0, weeks: 0 }).toISOString())
  );

  //---------------------------------------- mantine date picker
  const [selected_Year_Range, setSelected_Year_Range] = useState([22, 22]);
  const [selected_Month_Start, setSelected_Month_Start] = useState("1");
  const [selected_Month_End, setSelected_Month_End] = useState("12");

  //---------------------------------------- mantine rb

  // chart label & value Text
  const chartLabel_Text = useRef("");
  const cb_ChartValue_Text = ["QTY", "COST", "SRP", "PAID", "PROFIT"];

  const [chartValue_Text, setChartValue_Text] = useState("PROFIT");
  const hc_setChartValue_Text = (e) => {
    get_Data();
    setTimeout(() => {
      setChartValue_Text(e);
    }, 0);
  };

  // State
  const filterOption_State = useRef("");
  const cb_Itmes_State = ["Perak", "Kedah", "Kuantan", "Johor"];

  const hc_setFilterOption_State = (e) => {
    // reset RB
    filterOption_City.current = "";
    filterOption_Referrer.current = "";

    // get data
    _filter_state.current = true;
    _filter_city.current = true;
    _filter_referrer.current = false;
    _filter_item.current = false;
    get_Data();

    filterOption_State.current = e;
    chartLabel_Text.current = "City";

    // let result = [];

    // result = DB_Data.current.filter((x) => {
    //   if (filterOption_State.current != "") {
    //     return x.client_state == e;
    //   }
    // });

    // setDB_Data_Filtered(result);
  };

  // City
  const filterOption_City = useRef("");
  const cb_Itmes_City = ["A  Town", "P Town", "B Town", "Z Town", "Labis Town"];
  const hc_setFilterOption_City = (e) => {
    _filter_state.current = true;
    _filter_city.current = true;
    _filter_referrer.current = true;
    _filter_item.current = false;
    get_Data();

    filterOption_City.current = e;
    chartLabel_Text.current = "Referrer";

    // let result = [];

    // result = DB_Data.current.filter((x) => {
    //   if (filterOption_State.current != "" && filterOption_City.current != "") {
    //     return x.client_city == e; //&& x.client_state == filterOption_State;
    //   }
    // });
    // console.log("e 111", e);
    // console.log("DB_Data 111", DB_Data.current);
    // console.log("result 111", result);
    // setDB_Data_Filtered(result);
  };

  // Referrer
  const filterOption_Referrer = useRef("");
  const cb_Itmes_Referrer = ["1", "15", "12", "3", "6"];
  const hc_setFilterOption_Referrer = (e) => {
    _filter_state.current = true;
    _filter_city.current = true;
    _filter_referrer.current = true;
    _filter_item.current = true;
    get_Data();

    filterOption_Referrer.current = e;
    chartLabel_Text.current = "Item";

    // let result = [];

    // result = DB_Data.current.filter((x) => {
    //   if (
    //     filterOption_State.current != "" &&
    //     filterOption_City.current != "" &&
    //     filterOption_Referrer.current != ""
    //   ) {
    //     return x.referer_id == e; //&& x.client_state == filterOption_State;
    //   }
    // });
    // console.log("e 111", e);
    // console.log("DB_Data 111", DB_Data.current);
    // console.log("result 111", result);
    // setDB_Data_Filtered(result);
  };
  // Item
  const filterOption_Item = useRef("");
  const cb_Itmes_Item = ["1", "3", "4", "5", "6"];
  const hc_setFilterOption_Item = (e) => {
    _filter_state.current = true;
    _filter_city.current = true;
    _filter_referrer.current = true;
    _filter_item.current = true;
    get_Data();

    filterOption_Item.current = e;
    chartLabel_Text.current = "";

    // let result = [];

    // result = DB_Data.current.filter((x) => {
    //   if (
    //     filterOption_State.current != "" &&
    //     filterOption_City.current != "" &&
    //     filterOption_Referrer.current != "" &&
    //     filterOption_Item.current != ""
    //   ) {
    //     return x.item_id == e; //&& x.client_state == filterOption_State;
    //   }
    // });
    // console.log("e 111", e);
    // console.log("DB_Data 111", DB_Data.current);
    // console.log("result 111", result);
    // setDB_Data_Filtered(result);
  };

  //---------------------------------------- useEffect
  useEffect(() => {
    _filter_state.current = false;
    _filter_city.current = false;
    _filter_referrer.current = false;
    _filter_item.current = false;
    get_Data();
  }, []);

  return (
    <>
      <COM_NavBar_Global p_Selected_Button="3" />
      <COM_NavBar_Report p_Selected_Button="2" />
      <>
        <br />
        {/* <div>{selected_Year_Range[0]}</div>
        <div>{selected_Year_Range[1]}</div>
        <div>{selected_Month_Start}</div>
        <div>{selected_Month_End}</div> */}
        <COM_DatetimeSelector_Custom
          p_selected_Year_Range={selected_Year_Range}
          p_setSelected_Year_Range={setSelected_Year_Range}
          p_selected_Month_Start={selected_Month_Start}
          p_setSelected_Month_Start={setSelected_Month_Start} //ori setSelected_Month_Start
          p_selected_Month_End={selected_Month_End}
          p_setSelected_Month_End={setSelected_Month_End} // ori setSelected_Month_End
        />
      </>

      {/* {console.log("filterOption_State", filterOption_State.current)} */}
      {/* {console.log("filterOption_City", filterOption_City.current)} */}
      {/* //----------------------------------------- State - show stage & city */}
      <br />
      <div className="global_paper_lightGray">
        <h1>Filter 1</h1>
        <RadioGroup
          value={filterOption_State.current}
          onChange={(e) => hc_setFilterOption_State(e)}
          // defaultValue={['']}
          label="Filter State"
          // description=""
          required
        >
          {RB_Option_State.current.map((x) => (
            <Radio
              value={x}
              label={<div className="glo_RadioButton">{x}</div>}
            />
          ))}
          {/* {cb_Itmes_State.map((x) => (
          <Radio value={x} label={x} />
        ))} */}
        </RadioGroup>
        <hr />
        {/* //----------------------------------------- City - show stage, city & referer */}
        <RadioGroup
          value={filterOption_City.current}
          onChange={(e) => hc_setFilterOption_City(e)}
          // defaultValue={['']}
          label="Filter City"
          // description=""
          required
        >
          {RB_Option_City.current.map((x) => (
            <Radio
              value={x}
              label={<div className="glo_RadioButton">{x}</div>}
            />
          ))}
          {/* {cb_Itmes_City.map((x) => (
          <Radio value={x} label={x} />
        ))} */}
        </RadioGroup>
        <hr />
        {/* //----------------------------------------- Referrer -  show stage, city, referer & item*/}
        <RadioGroup
          value={filterOption_Referrer.current}
          onChange={(e) => hc_setFilterOption_Referrer(e)}
          // defaultValue={['']}
          label="Filter Referrer"
          // description=""
          required
        >
          {RB_Option_Referrer.current.map((x) => (
            <Radio
              value={x}
              label={<div className="glo_RadioButton">{x}</div>}
            />
          ))}
          {/* {cb_Itmes_Referrer.map((x) => (
          <Radio value={x} label={x} />
        ))} */}
        </RadioGroup>
        <hr />
        {/* //----------------------------------------- Item */}
        {/* <RadioGroup
        value={filterOption_Item.current}
        onChange={(e) => hc_setFilterOption_Item(e)}
        // defaultValue={['']}
        label="Filter Item"
        // description=""
        required
      >
        {cb_Itmes_Item.map((x) => (
          <Radio value={x} label={x} />
        ))}
      </RadioGroup> */}

        <h1>Filter 2</h1>
        {/* //----------------------------------------- Value Label Text */}
        <RadioGroup
          value={chartValue_Text}
          onChange={(e) => hc_setChartValue_Text(e)}
          // defaultValue={['']}
          label="Chart Value Category"
          // description=""
          required
        >
          {cb_ChartValue_Text.map((x) => (
            <Radio value={x} label={x} />
          ))}
        </RadioGroup>
      </div>
      <br />
      <br />
      <br />
      <br />
      {DB_Data_Filtered != undefined &&
      DB_Data_Filtered != null &&
      DB_Data_Filtered != [] ? (
        <Print_Report_Sales_Custom_COM
          p_Data={DB_Data_Filtered}
          p_Title={`Sales Report | ${chartValue_Text} vs ${chartLabel_Text.current}`}
          p_ChartLabel_Text={chartLabel_Text.current}
          p_ChartValue_Text={chartValue_Text}
          p_timeValue_Start={`20${selected_Year_Range[0]}-${selected_Month_Start}-01 00:00:00`} //{timeValue_Start}
          p_timeValue_End={`20${selected_Year_Range[1]}-${selected_Month_End}-31 23:59:59`} //{timeValue_End}
          p_filter_state={_filter_state}
          p_filter_city={_filter_city}
          p_filter_referrer={_filter_referrer}
          p_filter_item={_filter_item}
        />
      ) : (
        "Loading..."
      )}
      {/* {console.log("DB_Data77", DB_Data)}{" "} */}
    </>
  );
}
