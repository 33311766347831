import React, { Component, useEffect, useState, useRef } from "react";

import moment from "moment";

import { TimeInput } from "@mantine/dates";
import { Button } from "@mantine/core";

export default function COM_DateTimeMANTINE({
  mode,
  lable,
  desc,
  p_onChange,
  p_value,
}) {
  // const [timeValue, setTimeValue] = useState(new Date());

  return (
    <>
      <TimeInput
        defaultValue={new Date()}
        label={lable}
        description={desc}
        withSeconds
        required
        clearable
        value={p_value}
        onChange={p_onChange}
      />
      {/* <Button
        onClick={() => {
          console.log("timeValue", timeValue);
        }}
      >
        Check timeValue
      </Button> */}
    </>
  );
}
