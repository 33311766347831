import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import COM_AutoComplete_Referer from "../Component/COM_AutoComplete_Referer";

import { AiFillEdit } from "react-icons/ai";

import { CheckboxGroup, Checkbox } from "@mantine/core";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";

//  -------------------------- Material Table
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";
import COM_NavBar_User from "../Component/COM_NavBar_User";

import Print_Report_Sales_Monthly from "../Print/Print_Report_Sales_Monthly";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_SalesReports() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  //
  const [DB_Users, setDB_Users] = useState([]);
  const get_DB_Users = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__users__all`)
      .then((res) => {
        setDB_Users(res.data);
        console.log("get_DB_Users", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // update user role
  const set_DB_Users_Role = (
    _u_role,
    _u_uid,
    _auth_by,
    _auth_by_uuid,
    _auth_by_pic,
    _u_referer_list
  ) => {
    console.log("_u_referer_list", _u_referer_list);
    axios
      .post(`${REACT_APP_ECLIPSE_API}/set_vlerp__users__role_byuuid`, {
        u_role: _u_role,
        u_uid: _u_uid,
        auth_by: _auth_by,
        auth_by_uuid: _auth_by_uuid,
        auth_by_pic: _auth_by_pic,
        u_referer_list:
          _u_referer_list != "" && _u_referer_list != null
            ? JSON.stringify(
                _u_referer_list?.sort((a, b) => {
                  return a - b;
                })
              )
            : "",
      })
      .then((res) => {
        if (res.data.affectedRows > 0) {
          alert("SUCESSFULY Update!");
        } else {
          alert("FAILED update, please contact admin");
        }
        console.log("set_DB_Users_Role", res.data);
        get_DB_Users();
        setAC_SelectedData_Referer("");
      })
      .catch((err) => {
        setAC_SelectedData_Referer("");
        console.log("err", err);
      });
  };

  //
  const [DB_RefererList, setDB_RefererList] = useState([]);
  const get_DB_Referer_List = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__referer_info__all`)
      .then((res) => {
        setDB_RefererList(res.data);
        console.log("get_DB_Referer_List", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // ------------------------------------------------- Table Columns

  const columns = [
    {
      title: "User Id",
      field: "id",
      width: "1%",
      cellStyle: {
        backgroundColor: "rgba(0, 255, 255, 0.5)",
        color: "#000",
      },
      headerStyle: {
        backgroundColor: "rgba(0, 255, 255, 0.5)",
        color: "#000",
        maxWidth: "1px",
      },
    },

    {
      title: "User Role",
      field: "u_role",
      width: "1%",
      render: (rowData) => (
        <>{rowData.u_role?.toString()?.replaceAll("_", " ")}</>
      ),
    },
    {
      title: "User Role Lvl",
      field: "u_role_lvl",
      width: "1%",
    },
    {
      title: "User Referer Info",
      field: "u_referer_list",
      minWidth: "10rem",
      render: (rowData) => (
        <>
          {rowData?.u_referer_list != undefined &&
          rowData?.u_referer_list != "" ? (
            <>
              <div className="global_flex_col_cc global_flex_alignItems_Left">
                <div className="">
                  <b>{`${JSON.parse(rowData?.u_referer_list)}`}</b>
                </div>
              </div>
            </>
          ) : (
            <>NA</>
          )}
        </>
      ),
    },
    {
      title: "User Info",
      field: "u_firebase_obj",

      render: (rowData) => (
        <>
          <div className="global_flex_row_cc global_flex_alignItems_Left">
            <img
              className="img_UserProfile_small"
              src={`${JSON.parse(rowData.u_firebase_obj).photoURL}`}
            />

            <div className="padding_10">
              <div>{`${JSON.parse(rowData.u_firebase_obj)?.displayName}`}</div>
            </div>
          </div>
          {/* {rowData?.u_email} */}
          {/* {console.log("rowData1", rowData.u_firebase_obj)} */}
          {/* {console.log("rowData2", JSON.parse(rowData.u_firebase_obj))} */}
        </>
      ),
    },

    // {
    //   title: "User UUID",
    //   field: "u_uid",
    //   width: "1px",
    // },
    {
      title: "Login From",
      field: "u_providerId",
      // width: "1px",
    },
    {
      title: "User Register Datetime",
      field: "u_reg_dt",
      // width: "1px",
    },

    {
      title: "Auth by",
      field: "auth_dt",
      render: (rowData) => (
        <>
          <div className="global_flex_row_cc global_flex_alignItems_Left">
            <img
              className="img_UserProfile_small"
              src={`${rowData.auth_by_pic}`}
            />

            <div className="padding_10">
              <div>{`${rowData.auth_by}`}</div>
            </div>
          </div>
          {/* {rowData?.u_email} */}
          {/* {console.log("rowData1", rowData.u_firebase_obj)} */}
          {/* {console.log("rowData2", JSON.parse(rowData.u_firebase_obj))} */}
        </>
      ),
    },

    {
      title: "Auth Datetime",
      field: "auth_dt",
      // width: "1px",
    },
  ];
  // --------------------------------------- dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  const hd_DialogOpen = () => {
    setDialogOpen(true);
  };

  const hd_DialogClose = () => {
    setDialogOpen(false);
  };

  // --------------------------------------- check box
  const [cb_refererId, setCb_refererId] = useState([]);

  // --------------------------------------- radio button
  const [rb_Value, setRb_Value] = React.useState("");

  const hc_Rb_Value = (event) => {
    setRb_Value(event.target.value);
  };

  // --------------------------------------- global variable
  const selected_User_UUID = useRef("NA");

  const selectedTable_RowData = useRef([]);

  const [AC_SelectedData_Referer, setAC_SelectedData_Referer] = useState([]);

  // ------------------------------------------------- useEffect

  useEffect(() => {
    console.log("contextValue", contextValue);

    get_DB_Users();
    get_DB_Referer_List();
  }, []);
  return (
    <>
      <COM_NavBar_Global p_Selected_Button="5" />
      <COM_NavBar_User p_Selected_Button="1" />
      <h1>5.i. System User</h1>
      <br />
      <br />
      <br />
      <MaterialTable
        style={{
          MuiTablePaginationRoot: "gold",
          color: "black",
          // background: "rgba(0, 0, 100, .0)",
          backgroundImage:
            "linear-gradient(to bottom right, rgb(255, 255, 255), rgb(255, 255, 255))",
          boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
          backdropFilter: "blur( 11.0px )",
          "&::webkit-backdrop-filter": {
            webkitBackdropFilter: "blur( 18.0px )",
          },
        }}
        title={
          <div className="global_flex_row_cc">
            <h1>5.i. System User - Assingn Role</h1>
            <Button
              onClick={() => {
                get_DB_Users();
              }}
            >
              <ReplayIcon />
              Reload Data
            </Button>
          </div>
        }
        columns={columns}
        data={DB_Users}
        options={{
          headerStyle: {
            background: "rgba(255, 255, 255, .2)",
            border: "none",
          },
          exportButton: true,
          rowStyle: (rowData) => ({
            // backgroundColor: "#1a0000",
            // color: "#ffffff",
            fontSize: 12,
          }),
          filtering: true,
          // grouping: true,
          // exportAllData: true,
          pageSize: 10,
          pageSizeOptions: [20, 50, 100],
          paginationType: "stepped",
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
          // exportMenu: [
          //   {
          //     label: "Export PDF",
          //     exportFunc: (cols, datas) =>
          //       ExportPdf(cols, datas, "myPdfFileName"),
          //   },
          //   {
          //     label: "Export CSV",
          //     exportFunc: (cols, datas) =>
          //       ExportCsv(cols, datas, "myCsvFileName"),
          //   },
          // ],
        }}
        // onRowClick={(evt, selectedRow) => {
        //   //
        // }}

        actions={[
          {
            icon: "save",
            tooltip: "Save User",
            onClick: (event, selectedRow) => {
              selectedTable_RowData.current = selectedRow;

              setCb_refererId(
                selectedRow?.u_referer_list != ""
                  ? JSON.parse(selectedRow?.u_referer_list)
                  : []
              );
              console.log(
                "u_referer_list",
                selectedRow?.u_referer_list != ""
                  ? JSON.parse(selectedRow?.u_referer_list)?.toString()
                  : []
              );
              setRb_Value(selectedRow?.u_role);

              setTimeout(() => {
                hd_DialogOpen();
              }, 1000);
            },
          },
        ]}
        components={{
          Action: (props) => (
            <>
              {contextValue?.userInfo?.u_role_lvl == 0 ? (
                <div
                  className="btn_edit "
                  onClick={(event) => props.action.onClick(event, props.data)}
                >
                  <AiFillEdit style={{ transform: "scale(2)" }} />
                </div>
              ) : (
                <></>
              )}
            </>
          ),
        }}
      />

      <Dialog
        // onBackdropClick="false"
        // disableEscapeKeyDown
        fullScreen
        fullWidth={true}
        maxWidth="xl"
        open={dialogOpen}
        onClose={hd_DialogClose}
        style={
          {
            // background: "rgba(255, 195, 113, 1)",
            // backdropFilter: "blur( 5.0px )",
          }
        }
        PaperProps={{
          style: {
            // background: "rgba(255, 195, 113, .0)",
            // boxShadow: "0 0",
          },
        }}
      >
        <DialogTitle>{`5.i.a System User - details`}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <h1>User Info</h1>
          <h2>User uid</h2>
          {selectedTable_RowData?.current.u_uid}
          <h2>User Name</h2>
          {selectedTable_RowData?.current.u_name}
          <h2>User Role</h2>
          {selectedTable_RowData?.current.u_role}
          <br />
          <br />
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Selected : {rb_Value}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={rb_Value}
              onChange={hc_Rb_Value}
            >
              <FormControlLabel
                value="Admin"
                control={<Radio />}
                label="Lv0 : Admin"
              />
              <FormControlLabel
                value="Brand_Partner"
                control={<Radio />}
                label="Lv1 : Brand Partner"
              />
              <FormControlLabel
                value="Brand_Partner__Customer_Service"
                control={<Radio />}
                label="Lv2 : Brand Partner Customer Service"
              />
              <FormControlLabel
                value="Agent"
                control={<Radio />}
                label="Lv3 : Agent"
              />
              <FormControlLabel
                value="Agent_Customer__Service"
                control={<Radio />}
                label="Lv4 : Agent Customer Service"
              />
              <FormControlLabel
                value="Banned_User"
                control={<Radio />}
                label="Banned User"
              />
            </RadioGroup>
          </FormControl>

          <h2>Assign User Referer (Optional)</h2>
          {selectedTable_RowData?.current.u_referer_id}
          <div className="global_flex_width90">
            <h4>Referer Info</h4>
            <CheckboxGroup value={cb_refererId} onChange={setCb_refererId}>
              {DB_RefererList.map((x) => (
                <Checkbox
                  value={x.id.toString()}
                  label={
                    <>
                      <div>{`RefID: ${x.id}`}</div>
                      <div>{x.referer_name}</div>
                    </>
                  }
                />
              ))}
            </CheckboxGroup>
            {/* {console.log("cb_refererId", cb_refererId)} */}
          </div>
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <div
            className="btn_normal  "
            onClick={() => {
              hd_DialogClose();
            }}
          >
            <div className="padding_30">Cancel</div>
          </div>
          {/* {console.log(
            "contextValue.current.firebase_userInfo",
            contextValue.current.firebase_userInfo
          )} */}
          <div
            className="btn_normal "
            onClick={() => {
              // set_DB_Step2_to_Step3(
              //   selected_Order_Id.current,
              //   contextValue.current.firebase_userInfo.email
              // );
              set_DB_Users_Role(
                rb_Value,
                selectedTable_RowData.current.u_uid,
                contextValue.current.firebase_userInfo.displayName,
                contextValue.current.firebase_userInfo.uid,
                contextValue.current.firebase_userInfo.photoURL,
                cb_refererId
              );
              hd_DialogClose();
            }}
          >
            <div className="padding_30">Confirm</div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
