import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import COM_FileUpload from "../Component/COM_FileUpload";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import { TextInput } from "@mantine/core";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";
import COM_NavBar_Stock from "../Component/COM_NavBar_Stock";

import COM_AutoComplete_Item from "../Component/COM_AutoComplete_Item";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_SalesReports() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // update item info
  const set_DB_ItemInfo = (
    _id,
    _item_name,
    _item_desc,
    _item_price_cost,
    _item_price_srp
  ) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/set_vlerp__item__itemInfo`, {
        id: _id,
        item_name: _item_name,
        item_desc: _item_desc,
        item_price_cost: _item_price_cost,
        item_price_srp: _item_price_srp,
      })
      .then((res) => {
        console.log("# set_DB_ItemInfo", res.data);
        if (res.data?.affectedRows > 0) {
          alert("Update Completed");
        } else {
          alert("Update Failed! Please Contact Admin");
        }
      });
  };

  // -------------------------- Global variable

  const [AC_SelectedData_Item, setAC_SelectedData_Item] = useState([]);

  const [item_Id, setItem_Id] = useState("");
  const [item_Name, setItem_Name] = useState("");
  const [item_Desc, setItem_Desc] = useState("");
  const [item_Price_Cost, setItem_Price_Cost] = useState("");
  const [item_Price_SRP, setItem_Price_SRP] = useState("");

  // ------------------------------ UseEffect
  useEffect(() => {
    setItem_Id(AC_SelectedData_Item?.id);
    setItem_Name(AC_SelectedData_Item?.item_name);
    setItem_Desc(AC_SelectedData_Item?.item_desc);
    setItem_Price_Cost(AC_SelectedData_Item?.item_price_cost);
    setItem_Price_SRP(AC_SelectedData_Item?.item_price_srp);
  }, [AC_SelectedData_Item]);

  return (
    <>
      <COM_NavBar_Global p_Selected_Button="4" />
      <COM_NavBar_Stock p_Selected_Button="1" />
      <h1>4.i Item</h1>
      <br />
      <br />
      <br />
      <COM_AutoComplete_Item
        C_Title="Select Item"
        setAC_SelectedData_Item={setAC_SelectedData_Item}
      />
      {console.log("AC_SelectedData_Item", AC_SelectedData_Item)}

      <br />
      <br />
      <br />
      <div className="global_flex_row_cc global_bg_orange  padding_10">
        <div className="global_flex_row_cc  global_bg_gold width_20p ">
          Item ID
        </div>
        <div className="global_flex_row_cc global_bg_green1 width_80p">
          <TextInput
            value={item_Id}
            disabled
            onChange={(event) => setItem_Id(event.currentTarget.value)}
          />
        </div>
      </div>
      <div className="global_flex_row_cc global_bg_orange  padding_10">
        <div className="global_flex_row_cc  global_bg_gold width_20p ">
          Item Name
        </div>
        <div className="global_flex_row_cc global_bg_green1 width_80p">
          <TextInput
            value={item_Name}
            onChange={(event) => setItem_Name(event.currentTarget.value)}
          />
        </div>
      </div>
      <div className="global_flex_row_cc global_bg_orange  padding_10">
        <div className="global_flex_row_cc  global_bg_gold width_20p ">
          Item Desc
        </div>
        <div className="global_flex_row_cc global_bg_green1 width_80p">
          <TextInput
            value={item_Desc}
            onChange={(event) => setItem_Desc(event.currentTarget.value)}
          />
        </div>
      </div>
      <div className="global_flex_row_cc global_bg_orange  padding_10">
        <div className="global_flex_row_cc  global_bg_gold width_20p ">
          Item Price Cost
        </div>
        <div className="global_flex_row_cc global_bg_green1 width_80p">
          <TextInput
            value={item_Price_Cost}
            onChange={(event) => setItem_Price_Cost(event.currentTarget.value)}
          />
        </div>
      </div>
      <div className="global_flex_row_cc global_bg_orange  padding_10">
        <div className="global_flex_row_cc  global_bg_gold width_20p ">
          Item Price SRP
        </div>
        <div className="global_flex_row_cc global_bg_green1 width_80p">
          <TextInput
            value={item_Price_SRP}
            onChange={(event) => setItem_Price_Cost(event.currentTarget.value)}
          />
        </div>
      </div>
      <div className="global_flex_row_cc global_bg_orange  padding_10">
        <div className="global_flex_row_cc  global_bg_gold width_20p ">
          Item Pic
        </div>
        <div className="global_flex_row_cc global_bg_green1 width_80p">
          {/* <img
            src={`https://eclipseapi3.freemyip.com/uploads/vlerp/product/${AC_SelectedData_Item?.id}__product001.jpg`}
          /> */}
          <div className="global_grid_2column_container_5050">
            <COM_FileUpload
              uploadAllow={true}
              maxSizeMB="0.5"
              maxWidthOrHeight="1024"
              buttonName_PostFix={"PHOTO 1 ( Jpg Only ) "}
              serverDirPath={"uploads/vlerp/product"}
              uploadFileName={item_Id + "__product001"}
              displayImgHeight="200px"
              fileTpe="jpg"
            />
            <COM_FileUpload
              uploadAllow={true}
              maxSizeMB="0.5"
              maxWidthOrHeight="1024"
              buttonName_PostFix={"PHOTO 1 ( Jpg Only ) "}
              serverDirPath={"uploads/vlerp/product"}
              uploadFileName={item_Id + "__product002"}
              displayImgHeight="200px"
              fileTpe="jpg"
            />
          </div>
        </div>
      </div>
      <div className="global_flex_column_cc">
        <br />
        <br />
        <br />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            set_DB_ItemInfo(
              item_Id,
              item_Name,
              item_Desc,
              item_Price_Cost,
              item_Price_SRP
            );
          }}
        >
          Update Item Info
        </Button>
        <br />
        <br />
        <br />
      </div>
    </>
  );
}
