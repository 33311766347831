import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function COM_NavBar({ p_Selected_Button }) {
  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  // --------------------------------------- Global variable
  const selected_Button = useRef(p_Selected_Button);

  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  return (
    <>
      <div className="NavBar_Container_CreateOrder global_flex_row_cc">
        <div
          className={
            selected_Button.current == "1"
              ? "NavBar_Btn_3_Selected"
              : "NavBar_Btn_3"
          }
          onClick={() =>
            (window.location.href = "#/Print_Report_Sales_Monthly")
          }
        >
          <div>i.</div>
          <div>Sales Report</div>
        </div>
        <div
          className={
            selected_Button.current == "2"
              ? "NavBar_Btn_3_Selected"
              : "NavBar_Btn_3"
          }
          onClick={() => (window.location.href = "#/Print_Report_Sales_Custom")}
        >
          <div>ii.</div>
          <div>Sales Report Custom</div>
        </div>
      </div>
    </>
  );
}
