import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";
import COM_NavBar_Order from "../Component/COM_NavBar_Order";
import COM_NavBar_Report from "../Component/COM_NavBar_Report";

import COM_DateMANTINE from "../Component/COM_DateMANTINE";
import COM_TimeMANTINE from "../Component/COM_TimeMANTINE";

import { UserContext } from "../UserContext";

import Print_Report_Sales_Monthly_COM from "../Print/Print_Report_Sales_Monthly_COM";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Print_Report_Sales_Monthly() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  //
  const [DB_Data, setDB_Data] = useState([]);
  const get_Data = () => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_vlerp__order_log__salesreport_monthly`,
        {
          date_from:
            moment(RC_DateValue[0]).format("YYYY-MM-DD") +
            " " +
            moment(timeValue_Start).format("HH:mm:ss"), // "2022-04-01 00:00:00",
          date_until:
            moment(RC_DateValue[1]).format("YYYY-MM-DD") +
            " " +
            moment(timeValue_End).format("HH:mm:ss"), //"2022-04-21 23:59:59",
        }
      )
      .then((res) => {
        setDB_Data(res.data);
        console.log("get_vlerp__order_log__salesreport_monthly", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //---------------------------------------- mantine time picker
  const [timeValue_Start, setTimeValue_Start] = useState(
    new Date("1990-01-01 00:00:00")
  );
  const [timeValue_End, setTimeValue_End] = useState(
    new Date("1990-01-01 23:59:59")
  );

  //---------------------------------------- mantine date picker
  // - Range DateValue
  const [RC_DateValue, setRC_DateValue] = useState([
    new Date(moment().add({ days: 0, weeks: -144 }).toISOString()),
    new Date(moment().add({ days: 1, weeks: 0 }).toISOString()),
  ]);

  //  - Normal DateValue
  const [NC_DateValue, setNC_DateValue] = useState(
    new Date(moment().add({ days: 0, weeks: 0 }).toISOString())
  );

  //
  useEffect(() => {
    get_Data();
  }, []);

  return (
    <>
      <COM_NavBar_Global p_Selected_Button="3" />

      <COM_NavBar_Report p_Selected_Button="1" />
      <div className="global_grid_2column_container_5050 global_paper_gray ">
        <div className="global_flex_column_cc    ">
          <h3>1. Select Dates</h3>
          <COM_DateMANTINE
            bg_color="#ffffff"
            mode="range" // normal / range
            p_value={RC_DateValue} // NC_DateValue/ RC_DateValue
            p_onChange={setRC_DateValue} // setNC_DateValue / setRC_DateValue
          />
        </div>
        <div>
          <div className="global_flex_row_cc  ">
            <div className="po_1_cotp__dateText">From : </div>

            <div className="po_1_cotp__dateText">
              {moment(RC_DateValue[0]).format("YYYY-MM-DD")}
            </div>
            <div className="global_flex_width20">
              <COM_TimeMANTINE
                lable="2a. Start Time"
                desc=""
                p_value={timeValue_Start}
                p_onChange={setTimeValue_Start}
              />
            </div>
          </div>
          <div className="global_flex_row_cc  ">
            <div className="po_1_cotp__dateText">Until :</div>

            <div className="po_1_cotp__dateText">
              {moment(RC_DateValue[1]).format("YYYY-MM-DD")}
            </div>
            <div className="global_flex_width20">
              <COM_TimeMANTINE
                lable="2b. End Time"
                desc=""
                p_value={timeValue_End}
                p_onChange={setTimeValue_End}
              />
            </div>
          </div>
          <div className="global_flex_row_cc  ">
            <div
              className="btn_normal "
              onClick={() => {
                get_Data();
              }}
            >
              <div className="global_text_28px padding_30">Generate Report</div>
              <div>
                {`From : ${moment(RC_DateValue[0]).format(
                  "YYYY-MM-DD"
                )} ${moment(timeValue_Start).format("HH:mm:ss")} `}
              </div>
              <div>
                {`Until : ${moment(RC_DateValue[1]).format(
                  "YYYY-MM-DD"
                )} ${moment(timeValue_End).format("HH:mm:ss")}`}
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />

      {DB_Data != undefined && DB_Data != null && DB_Data != [] ? (
        <Print_Report_Sales_Monthly_COM
          p_Data={DB_Data}
          p_Title="Sales Report - Monthly"
        />
      ) : (
        "Loading..."
      )}
      {/* {console.log("DB_Data77", DB_Data)}{" "} */}
    </>
  );
}
