import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";
import COM_NavBar_Order from "../Component/COM_NavBar_Order";
import COM_AutoComplete_Client from "../Component/COM_AutoComplete_Client";
import COM_AutoComplete_Referer from "../Component/COM_AutoComplete_Referer";
import COM_AutoComplete_Item from "../Component/COM_AutoComplete_Item";
import COM_ItemCart from "../Component/COM_ItemCart";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  //
  // const [DB_ClientList, setDB_ClientList] = useState([]);
  const add_DB_NewOrder = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/add__vlerp__order_log__NewOrder`, {
        order_template: "Custom Order",
        order_template_uuid: "Custom Order",
        order_template_from: "0000-00-00 00:00:00",
        order_template_until: "0000-00-00 00:00:00",

        clientInfo: AC_SelectedData_Client,
        refererInfo: AC_SelectedData_Referer,
        itemInfo: parent_ItemCart,
        courierInfo: selected_CourierFee,
        step1_by: contextValue.current.firebase_userInfo.email,
      })
      .then((res) => {
        // setDB_ClientList(res.data);
        console.log("add__vlerp__order_log__NewOrder", res.data);
        if (res.data[4].affectedRows > 0) {
          alert("Successfully Created new Order");
          // window.location.reload();
        } else {
          alert("Failed to Created new Order! , please contact admin");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // --------------------------------------- Global variable
  const receiver_Name = useRef("");

  const [AC_SelectedData_Client, setAC_SelectedData_Client] = useState([]);
  const [AC_SelectedData_Referer, setAC_SelectedData_Referer] = useState([]);
  const [AC_SelectedData_Item, setAC_SelectedData_Item] = useState([]);

  const [selected_CourierFee, setSelected_CourierFee] = useState("");
  const [selected_ItemQty, setSelected_ItemQty] = useState("");
  const [selected_ItemPrice, setSelected_ItemPirce] = useState("");

  const [parent_ItemCart, setParent_ItemCart] = useState([]);

  useEffect(() => {}, []);

  return (
    <>
      <COM_NavBar_Global />
      <COM_NavBar_Order />

      <br />
      <br />
      {/* <h1>Page_Order_1a_CreateOrder</h1>
      <div>{`uid : ${contextValue.current.firebase_userInfo.uid}`}</div>
      <div>{`displayName : ${contextValue.current.firebase_userInfo.displayName}`}</div>
      <div>{`email : ${contextValue.current.firebase_userInfo.email}`}</div>
      <div>{`providerId : ${contextValue.current.firebase_userInfo.providerData[0]?.providerId}`}</div>
      <img
        src={contextValue.current.firebase_userInfo.providerData[0]?.photoURL}
        style={{ height: "100px" }}
      /> */}

      <h1>1. Create Order</h1>
      <br />
      <div className="global_flex_column_cc">
        <div className="global_flex_width90">
          <h4>1. Client Info</h4>
          <COM_AutoComplete_Client
            C_Title="1. Select Client"
            setAC_SelectedData_Client={setAC_SelectedData_Client}
          />

          <br />
        </div>

        <div className="global_flex_width90">
          <h4>2. Referer Info</h4>
          <COM_AutoComplete_Referer
            C_Title="2. Select Referer"
            setAC_SelectedData_Referer={setAC_SelectedData_Referer}
          />

          <br />
        </div>
        <div className="global_flex_width90">
          <h4>3. Courier Fee </h4>
          <TextField
            fullWidth
            // autoFocus
            margin="dense"
            id="name"
            // value={}
            onChange={(event) => setSelected_CourierFee(event.target.value)}
            label="3. Key in Courier Fee"
            type="text"
            variant="standard"
          />
        </div>
        <div className="global_flex_width90">
          <h4>4. Item</h4>
          <br />
          <COM_AutoComplete_Item
            C_Title="4a. Chose Item"
            setAC_SelectedData_Item={setAC_SelectedData_Item}
          />

          <div className="global_grid_4column_container_25252525  ">
            <div />
            <div />
            <TextField
              fullWidth
              // autoFocus
              margin="dense"
              id="name"
              // value={}
              onChange={(event) => setSelected_ItemQty(event.target.value)}
              label="4b.Item Quantity"
              type="text"
              variant="standard"
            />
            <TextField
              fullWidth
              // autoFocus
              margin="dense"
              id="name"
              // value={}
              onChange={(event) => setSelected_ItemPirce(event.target.value)}
              label="4c.Item Price (RM)"
              type="text"
              variant="standard"
            />

            {/* <Button
              onClick={() => {
                console.log("parent_ItemCart", parent_ItemCart);
              }}
            >
              Check List
            </Button> */}
          </div>
          <COM_ItemCart
            p_data={{
              item_id: AC_SelectedData_Item.id,
              item_name: AC_SelectedData_Item?.item_name,

              item_desc: AC_SelectedData_Item?.item_desc,
              item_price_cost: AC_SelectedData_Item?.item_price_cost,
              item_price_srp: AC_SelectedData_Item?.item_price_srp,
              item_gen_by: AC_SelectedData_Item?.item_gen_by,

              selected_ItemPrice: selected_ItemPrice,
              selected_ItemQty: selected_ItemQty,
            }}
            setParent_ItemCart={setParent_ItemCart}
          />

          <br />
          <div className="global_grid_2column_container_2080 padding_10">
            <div>ID</div>
            <div>{`${
              AC_SelectedData_Item?.id != undefined
                ? AC_SelectedData_Item.id
                : ""
            }`}</div>
            <div>Name</div>
            <div>{`${AC_SelectedData_Item?.item_name}`}</div>
            <div>Desc</div>
            <div>{`${AC_SelectedData_Item?.item_desc}`}</div>
            <div>Cost (RM)</div>
            <div>{`${AC_SelectedData_Item?.item_price_cost}`}</div>
            <div>SRP (RM)</div>
            <div>{`${AC_SelectedData_Item?.item_price_srp}`}</div>
          </div>
        </div>
        <br />
        <br />
        <br />
        {/* -------------------------------------- Summary  */}
        <div className="po_1_co__summary ">
          <h1>Summary</h1>
          <div className="global_grid_2column_container_2080 global_flex_width90 padding_10   ">
            <h3>1. Client Info </h3>
            <div></div>
            <div>ID</div>
            <div>{`${
              AC_SelectedData_Client?.id != undefined
                ? AC_SelectedData_Client.id
                : ""
            }`}</div>
            <div>Name</div>
            <div>{`${AC_SelectedData_Client?.client_name}`}</div>
            <div>Contact</div>
            <div>{`${AC_SelectedData_Client?.client_contact}`}</div>
            <div>Address</div>
            <div>{`${AC_SelectedData_Client?.client_address}`}</div>
            <div>Client Referer</div>
            <div>{`[RefId: ${AC_SelectedData_Client?.client_referer_id}](${AC_SelectedData_Client?.client_referer_role}) ${AC_SelectedData_Client?.client_referer_name}`}</div>
          </div>
          {console.log("AC_SelectedData_Client", AC_SelectedData_Client)}
          <div className="global_grid_2column_container_2080 global_flex_width90  padding_10">
            <h3>2. Referer Info </h3>
            <div></div>
            <div>ID</div>
            <div>{`${
              AC_SelectedData_Referer?.id != undefined
                ? AC_SelectedData_Referer.id
                : ""
            }`}</div>
            <div>Name</div>
            <div>{`${AC_SelectedData_Referer?.referer_name}`}</div>
            <div>Contact</div>
            <div>{`${AC_SelectedData_Referer?.referer_contact}`}</div>
            <div>Address</div>
            <div>{`${AC_SelectedData_Referer?.referer_address}`}</div>
          </div>

          <div className="global_grid_2column_container_2080 global_flex_width90  padding_10">
            <h3>3. Courier Fee </h3>
            <div></div>
            {selected_CourierFee}
          </div>

          <div className="po_1_co__grid_6column_container global_flex_width90  padding_10 po_1_co__textwarp">
            <h3>4. Item(s) </h3>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>

            <div>SID</div>
            <div>Item Id</div>
            <div>Item Name</div>
            <div>Unit Price (RM)</div>
            <div>Item Qty (RM)</div>
            <div>Sub Total (RM)</div>

            {parent_ItemCart.map((x) => (
              <>
                <div>{x.id}</div>
                <div>{`${x.item.item_id}`}</div>
                <div>{`${x.item.item_name}`}</div>
                <div>{`${x.item.selected_ItemPrice}`}</div>
                <div>{`${x.item.selected_ItemQty}`}</div>
                <div>{`${
                  x.item.selected_ItemQty * x.item.selected_ItemPrice
                }`}</div>
              </>
            ))}

            <div> </div>
            <div> </div>
            <div>{`${parent_ItemCart.length} Item(s)`}</div>
            <div> </div>
            <div>
              {`${parent_ItemCart
                ?.reduce(
                  (acc, { item }) => acc + parseFloat(item.selected_ItemQty),
                  0
                )
                .toFixed()} Item(s)`}{" "}
            </div>
            <div>
              {`Total : ${parent_ItemCart
                ?.reduce(
                  (acc, { item }) =>
                    acc +
                    parseFloat(item.selected_ItemQty) *
                      parseFloat(item.selected_ItemPrice),
                  0
                )
                .toFixed(2)}`}{" "}
            </div>
          </div>
          <div
            className="btn_normal"
            onClick={() => {
              add_DB_NewOrder();
            }}
          >
            Create New Order
          </div>
        </div>
      </div>
    </>
  );
}
