import React from "react";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";

export default function Page_Main() {
  return (
    <>
      <COM_NavBar_Global />
      <br />
      <br />
      <br />
      <br />
      <br />
      Page Main
    </>
  );
}
