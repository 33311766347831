import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import moment from "moment";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";

import COM_NavBar_Order from "../Component/COM_NavBar_Order";

import COM_AutoComplete_Referer from "../Component/COM_AutoComplete_Referer";

import COM_AutoComplete_Item from "../Component/COM_AutoComplete_Item";
import COM_ItemCart__OrderTemplate from "../Component/COM_ItemCart__OrderTemplate";

import COM_DateMANTINE from "../Component/COM_DateMANTINE";
import COM_TimeMANTINE from "../Component/COM_TimeMANTINE";
import { NumberInput } from "@mantine/core";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormGroup,
  FormControl,
  FormLabel,
} from "@mui/material";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X({ hd_DialogClose, db_reload }) {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  //
  // const [DB_ClientList, setDB_ClientList] = useState([]);
  const add_DB_NewOrder_Template = () => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/add__vlerp__order_log__NewOrderTemplate`,
        {
          tp_name: selected_TemplateName,
          tp_dt_from:
            moment(RC_DateValue[0]).format("YYYY-MM-DD") +
            " " +
            moment(timeValue_Start).format("HH:mm:ss"),
          tp_dt_until:
            moment(RC_DateValue[1]).format("YYYY-MM-DD") +
            " " +
            moment(timeValue_End).format("HH:mm:ss"),

          referer_id: AC_SelectedData_Referer.id,
          referer_name: AC_SelectedData_Referer.referer_name,
          referer_contact: AC_SelectedData_Referer.referer_contact,
          referer_role: AC_SelectedData_Referer.referer_role,

          itemInfo: parent_ItemCart,

          courier_region: rb_Courier_Region,
          courier_fee: rb_Courier_Fees,

          tp_gen_by: contextValue.current.firebase_userInfo.displayName,
          tp_gen_by_uid: contextValue.current.firebase_userInfo.uid,
          tp_gen_by_pic:
            contextValue.current.firebase_userInfo.providerData[0]?.photoURL,

          selected_Item_PricePerUnit: selected_Item_PricePerUnit,
          selected_Item_MinOrderQty: selected_Item_MinOrderQty,
        }
      )
      .then((res) => {
        // setDB_ClientList(res.data);
        console.log("add_DB_NewOrder", res.data);

        if (res.data[5].affectedRows > 0) {
          hd_DialogClose();
          db_reload();
          alert("SUCCESSFUL to Add New Order Template! ");
        } else {
          alert("FAILED to Add New Order Template! Please Contact Admin");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // -------------------------- Global variable

  const [selected_TemplateName, setSelected_TemplateName] = useState("");

  const [AC_SelectedData_Referer, setAC_SelectedData_Referer] = useState([]);

  const [AC_SelectedData_Item, setAC_SelectedData_Item] = useState([]);

  const [selected_CourierFee, setSelected_CourierFee] = useState("");
  const [selected_Item_MinOrderQty, setSelected_Item_MinOrderQty] =
    useState("");
  const [selected_Item_PricePerUnit, setSelected_Item_PircePerUnit] =
    useState("");

  const [parent_ItemCart, setParent_ItemCart] = useState([]);

  // --------------------------------------- Radio
  const [rb_Courier_Fees, setRb_Courier_Fees] = React.useState("");

  const hc_Rb_Courier_Fees = (value) => {
    setRb_Courier_Fees(value);
  };
  const [rb_Courier_Region, setRb_Courier_Region] = React.useState("");

  const hc_Rb_Courier_Region = (event) => {
    setRb_Courier_Region(event.target.value);
  };
  const [rb_CODFees, setRb_CODFees] = React.useState(0);

  const hc_Rb_CODFees = (event) => {
    setRb_CODFees(event.target.value);
  };

  //---------------------------------------- mantine time picker
  const [timeValue_Start, setTimeValue_Start] = useState(
    new Date("1990-01-01 00:00:00")
  );
  const [timeValue_End, setTimeValue_End] = useState(
    new Date("1990-01-01 23:59:59")
  );
  //---------------------------------------- mantine date picker
  // - Range DateValue
  const [RC_DateValue, setRC_DateValue] = useState([
    new Date(moment().toISOString()),
    new Date(moment().add({ days: 1, weeks: 1 }).toISOString()),
  ]);

  //  - Normal DateValue
  const [NC_DateValue, setNC_DateValue] = useState(
    new Date(moment().add({ days: 0, weeks: 0 }).toISOString())
  );

  useEffect(() => {}, []);

  useEffect(() => {}, []);

  return (
    <>
      <div className="global_flex_column_cc">
        <div className="global_flex_width90">
          <h3>1. Template Name</h3>

          <TextField
            fullWidth
            // autoFocus
            margin="dense"
            id="name"
            // value={}
            onChange={(event) => setSelected_TemplateName(event.target.value)}
            placeholder="Template Name"
            helperText="ie. Mega Sales 4896"
            type="text"
            variant="standard"
          />
        </div>

        <div className="global_flex_width90 ">
          <h3>2. Choose Valid Date</h3>
          <div className="global_flex_row_cc global_paper_gray ">
            <COM_DateMANTINE
              bg_color="#ffffff"
              mode="range" // normal / range
              p_value={RC_DateValue} // NC_DateValue/ RC_DateValue
              p_onChange={setRC_DateValue} // setNC_DateValue / setRC_DateValue
            />
          </div>
          <br />
          <br />

          <div className="global_flex_row_cc global_paper_gray ">
            <div className="po_1_cotp__dateText">
              {moment(RC_DateValue[0]).format("YYYY-MM-DD")}
            </div>
            <div className="global_flex_width20">
              <COM_TimeMANTINE
                lable="2a. Start Time"
                desc=""
                p_value={timeValue_Start}
                p_onChange={setTimeValue_Start}
              />
            </div>

            <div className="po_1_cotp__dateText">Until</div>

            <div className="po_1_cotp__dateText">
              {moment(RC_DateValue[1]).format("YYYY-MM-DD")}
            </div>
            <div className="global_flex_width20">
              <COM_TimeMANTINE
                lable="2b. End Time"
                desc=""
                p_value={timeValue_End}
                p_onChange={setTimeValue_End}
              />
            </div>
            <Button
              onClick={() => {
                console.log(
                  "timeValue",
                  moment(timeValue_End).format("HH:mm:ss")
                );
              }}
            >
              Check
            </Button>
          </div>
          <br />
        </div>

        <div className="global_flex_width90">
          <h4>3. Referer Info</h4>
          <COM_AutoComplete_Referer
            C_Title="Select Referer"
            setAC_SelectedData_Referer={setAC_SelectedData_Referer}
          />
          {console.log("AC_SelectedData_Referer", AC_SelectedData_Referer)}
          <br />
        </div>

        <div className="global_flex_width90">
          <h4>4. Item</h4>
          <br />
          <COM_AutoComplete_Item
            C_Title="Select Item"
            setAC_SelectedData_Item={setAC_SelectedData_Item}
          />
          <div className="global_grid_4column_container_25252525  ">
            <div />
            <div />
            <div className="global_flex_width90">
              <NumberInput
                defaultValue={0}
                label="Item Min Order Quantity"
                value={selected_Item_MinOrderQty}
                onChange={(value) => setSelected_Item_MinOrderQty(value)}
                min={0}
                parser={(value) => value.replace(/\$\s?|(,*)[^0-9.]/g, "")}
              />
            </div>
            <div className="global_flex_width90">
              <NumberInput
                defaultValue={0}
                label="Item Price Per Unit"
                value={selected_Item_PricePerUnit}
                onChange={(value) => setSelected_Item_PircePerUnit(value)}
                precision={2}
                min={0}
                parser={(value) => value.replace(/\$\s?|(,*)[^0-9.]/g, "")}
                formatter={(value) =>
                  !Number.isNaN(parseFloat(value))
                    ? `RM ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "RM "
                }
              />
            </div>
          </div>
          <COM_ItemCart__OrderTemplate
            p_data={{
              item_id: AC_SelectedData_Item.id,
              item_name: AC_SelectedData_Item?.item_name,

              item_desc: AC_SelectedData_Item?.item_desc,
              item_price_cost: AC_SelectedData_Item?.item_price_cost,
              item_price_srp: AC_SelectedData_Item?.item_price_srp,
              item_gen_by: AC_SelectedData_Item?.item_gen_by,
              item_gen_dt: AC_SelectedData_Item?.item_gen_dt,

              selected_Item_PricePerUnit: selected_Item_PricePerUnit,
              selected_Item_MinOrderQty: selected_Item_MinOrderQty,
            }}
            setParent_ItemCart={setParent_ItemCart}
          />

          <br />

          <div />
          <div />
          <div>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <h3>{`Courier Fee :   `}</h3>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={rb_Courier_Region}
                onChange={hc_Rb_Courier_Region}
              >
                <FormControlLabel
                  value="East_Malaysia"
                  control={<Radio />}
                  label="East Malaysia"
                />
                <FormControlLabel
                  value="West_Malaysia"
                  control={<Radio />}
                  label="West Malaysia"
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="">
            <NumberInput
              defaultValue={0}
              label="5. Courier Fee"
              value={rb_Courier_Fees}
              onChange={hc_Rb_Courier_Fees}
              precision={2}
              min={0}
              parser={(value) => value.replace(/\$\s?|(,*)[^0-9.]/g, "")}
              formatter={(value) =>
                !Number.isNaN(parseFloat(value))
                  ? `RM ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "RM "
              }
            />
          </div>
        </div>
        <br />

        <div className="global_flex_width90 ">
          <div
            className="btn_normal  "
            onClick={() => {
              add_DB_NewOrder_Template();
            }}
          >
            <div className="padding_30">Create New Order Template</div>
          </div>
        </div>
      </div>
    </>
  );
}
