import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import Chart_Pie from "../Chart/Chart_Pie";
import Chart_Line from "../Chart/Chart_Line";

// import { ComponentToPrint } from "./ComponentToPrint";

const X = ({
  p_Data,
  p_Title,
  p_ChartLabel_Text,
  p_ChartValue_Text,
  p_timeValue_Start,
  p_timeValue_End,
  p_filter_state,
  p_filter_city,
  p_filter_referrer,
  p_filter_item,
}) => {
  const componentRef = useRef();

  // convert to chart data
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    console.log("# X - p_Data", p_Data);

    // convert JSON to match Chat
    let result = [];

    // result = p_Data.filter((x) => {
    //   return x.client_state == "Perak";
    // });

    result = p_Data.map((item) => {
      if (p_ChartLabel_Text == "City") {
        return {
          id: `${item.client_city}`,
          label: `${item.client_city}`,
          value: `${
            p_ChartValue_Text == "QTY"
              ? `${item.total_selected_ItemQty}`
              : p_ChartValue_Text == "COST"
              ? `${item.total_item_price_cost}`
              : p_ChartValue_Text == "SRP"
              ? `${item.total_item_price_srp}`
              : p_ChartValue_Text == "PAID"
              ? `${item.total_payment_total_amount_paid}`
              : p_ChartValue_Text == "PROFIT"
              ? `${item.total_profit}`
              : `-1`
          }`,
        };
      }
      if (p_ChartLabel_Text == "Referrer") {
        return {
          id: `${item.referer_id}`,
          label: `${item.referer_id}`,
          value: `${
            p_ChartValue_Text == "QTY"
              ? `${item.total_selected_ItemQty}`
              : p_ChartValue_Text == "COST"
              ? `${item.total_item_price_cost}`
              : p_ChartValue_Text == "SRP"
              ? `${item.total_item_price_srp}`
              : p_ChartValue_Text == "PAID"
              ? `${item.total_payment_total_amount_paid}`
              : p_ChartValue_Text == "PROFIT"
              ? `${item.total_profit}`
              : `-1`
          }`,
        };
      }
      if (p_ChartLabel_Text == "Item") {
        return {
          id: `[ID:${item.item_id}] ${item.item_name}`,
          label: `[ID:${item.item_id}] ${item.item_name}`,
          value: `${
            p_ChartValue_Text == "QTY"
              ? `${item.total_selected_ItemQty}`
              : p_ChartValue_Text == "COST"
              ? `${item.total_item_price_cost}`
              : p_ChartValue_Text == "SRP"
              ? `${item.total_item_price_srp}`
              : p_ChartValue_Text == "PAID"
              ? `${item.total_payment_total_amount_paid}`
              : p_ChartValue_Text == "PROFIT"
              ? `${item.total_profit}`
              : `-1`
          }`,
        };
      }
      if (p_ChartLabel_Text == "") {
        return {
          id: `${item.client_state}`,
          label: `${item.client_state}`,
          value: `${
            p_ChartValue_Text == "QTY"
              ? `${item.total_selected_ItemQty}`
              : p_ChartValue_Text == "COST"
              ? `${item.total_item_price_cost}`
              : p_ChartValue_Text == "SRP"
              ? `${item.total_item_price_srp}`
              : p_ChartValue_Text == "PAID"
              ? `${item.total_payment_total_amount_paid}`
              : p_ChartValue_Text == "PROFIT"
              ? `${item.total_profit}`
              : `-1`
          }`,
        };
      }
    });

    setChartData(result);
  }, [p_Data]);

  return (
    <div>
      <div className="pr__printBtn_Title">
        <ReactToPrint
          trigger={() => (
            <button className="btn_print ">
              <div className="padding_30">Print</div>
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div className="pr__page_display_printPage">
        <ComponentToPrint
          ref={componentRef}
          p2_Data={p_Data}
          p_chartData={chartData}
          p_Title={p_Title}
          p2_timeValue_Start={p_timeValue_Start}
          p2_timeValue_End={p_timeValue_End}
          p2_filter_state={p_filter_state}
          p2_filter_city={p_filter_city}
          p2_filter_referrer={p_filter_referrer}
          p2_filter_item={p_filter_item}
        />
      </div>
    </div>
  );
};

const pageStyle = () => {
  return `
  
`;
};

// ------------------------------------------------------  printing content
class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // c_Data: props.p_Data,
    };
  }

  componentDidMount = () => {
    console.log("p2_Data", this.props.p2_Data);
  };

  render() {
    const HeaderTitle = () => {
      return (
        <>
          <div className="pr__title_container">
            <img
              className="pr__title_logo"
              src={`https://eclipseapi3.freemyip.com/uploads/EclipseMy_Web/Company_Logo.png`}
            />

            <div>
              <div className="pr__title1">{this.props.p_Title} </div>
              <div className="pr__title2">
                {`from 
                ${this.props.p2_timeValue_Start}
                to
                ${this.props.p2_timeValue_End}
                
                `}

                {/* {`${this.props.p2_Data?.at(0)?.paid_date} - ${
                  this.props.p2_Data?.at(-1)?.paid_date
                }`} */}
                {/* {`from 
                  ${
                    this.props?.p2_timeValue_Start
                      ?.toLocaleDateString()
                      ?.split("/")[2]
                  }-${
                  this.props?.p2_timeValue_Start
                    ?.toLocaleDateString()
                    ?.split("/")[1]
                }-${
                  this.props?.p2_timeValue_Start
                    ?.toLocaleDateString()
                    ?.split("/")[0]
                } to 
                ${
                  this.props?.p2_timeValue_End
                    ?.toLocaleDateString()
                    ?.split("/")[2]
                }-${
                  this.props?.p2_timeValue_End
                    ?.toLocaleDateString()
                    ?.split("/")[1]
                }-${
                  this.props?.p2_timeValue_End
                    ?.toLocaleDateString()
                    ?.split("/")[0]
                }
                    `}
                {console.log(
                  "asdasd",
                  this.props?.p2_timeValue_Start?.toLocaleDateString()
                )} */}
              </div>
            </div>
          </div>
        </>
      );
    };

    const nivo_dbData = [
      {
        id: "c",
        label: "c",
        value: 85,
      },
      {
        id: "rust",
        label: "rust",
        value: 582,
      },
      {
        id: "php",
        label: "php",
        value: 91,
      },
      {
        id: "sass",
        label: "sass",
        value: 531,
      },
      {
        id: "javascript",
        label: "javascript",
        value: 458,
      },
    ];
    return (
      <div>
        {console.log("p2_Data", this.props.p2_Data)}
        {console.log("p_chartData", this.props.p_chartData)}

        <style type="text/css" media="print">
          {pageStyle()}
        </style>
        <HeaderTitle />

        <Chart_Pie p_dbData={this.props.p_chartData} />
        {/* <Chart_Pie p_dbData={nivo_dbData} /> */}
        <div>
          {/* {console.log("props.p_Data", this.props.p_Data)} */}
          {/* {this.props.p_Data[0]?.paid_date} */}
          <table className="pr__table">
            <tr>
              <th className="pr__th">#</th>
              <th className="pr__th">State</th>
              {this.props?.p2_filter_state.current == true &&
              this.props?.p2_filter_city.current == true ? (
                <th className="pr__th">City</th>
              ) : (
                ""
              )}
              {this.props?.p2_filter_state.current == true &&
              this.props?.p2_filter_city.current == true &&
              this.props?.p2_filter_referrer.current == true ? (
                <th className="pr__th">Referrer ID</th>
              ) : (
                ""
              )}
              {this.props?.p2_filter_state.current == true &&
              this.props?.p2_filter_city.current == true &&
              this.props?.p2_filter_referrer.current == true &&
              this.props?.p2_filter_item.current == true ? (
                <th className="pr__th">Item ID</th>
              ) : (
                ""
              )}
              {this.props?.p2_filter_state.current == true &&
              this.props?.p2_filter_city.current == true &&
              this.props?.p2_filter_referrer.current == true &&
              this.props?.p2_filter_item.current == true ? (
                <th className="pr__th">Total Item Qty</th>
              ) : (
                ""
              )}

              <th className="pr__th">Total Cost RM</th>
              <th className="pr__th">Total SRP RM</th>
              <th className="pr__th">Total Profit RM</th>
              <th className="pr__th">Total Paid RM</th>
            </tr>
            {this.props.p2_Data?.map((x, index) => (
              <>
                <tr>
                  <td className="pr__td">{index + 1}</td>
                  <td className="pr__td">{x.client_state}</td>
                  {this.props?.p2_filter_state.current == true &&
                  this.props?.p2_filter_city.current == true ? (
                    <td className="pr__td">{x.client_city}</td>
                  ) : (
                    ""
                  )}
                  {this.props?.p2_filter_state.current == true &&
                  this.props?.p2_filter_city.current == true &&
                  this.props?.p2_filter_referrer.current == true ? (
                    <td className="pr__td">{x.referer_id}</td>
                  ) : (
                    ""
                  )}
                  {this.props?.p2_filter_state.current == true &&
                  this.props?.p2_filter_city.current == true &&
                  this.props?.p2_filter_referrer.current == true &&
                  this.props?.p2_filter_item.current == true ? (
                    <td className="pr__td">{x.item_id}</td>
                  ) : (
                    ""
                  )}
                  {this.props?.p2_filter_state.current == true &&
                  this.props?.p2_filter_city.current == true &&
                  this.props?.p2_filter_referrer.current == true &&
                  this.props?.p2_filter_item.current == true ? (
                    <td className="pr__td">{x.total_selected_ItemQty}</td>
                  ) : (
                    ""
                  )}
                  <td className="pr__td">{x.total_item_price_cost}</td>
                  <td className="pr__td">{x.total_item_price_srp}</td>
                  <td className="pr__td">{x.total_profit}</td>
                  <td className="pr__td">
                    {parseFloat(x.total_payment_total_amount_paid).toFixed(2)}
                  </td>
                </tr>
                {/* <div>{index % 2}</div> */}
                <div>
                  {(index > 0 && index % 2) == 0 ? (
                    <div className="page-break" />
                  ) : (
                    <div />
                  )}
                </div>
              </>
            ))}

            <tr>
              <td> </td>
              <td>GRAND TOTAL</td>

              {this.props?.p2_filter_state.current == true &&
              this.props?.p2_filter_city.current == true ? (
                <td> </td>
              ) : (
                ""
              )}
              {this.props?.p2_filter_state.current == true &&
              this.props?.p2_filter_city.current == true &&
              this.props?.p2_filter_referrer.current == true ? (
                <td> </td>
              ) : (
                ""
              )}
              {this.props?.p2_filter_state.current == true &&
              this.props?.p2_filter_city.current == true &&
              this.props?.p2_filter_referrer.current == true &&
              this.props?.p2_filter_item.current == true ? (
                <td> </td>
              ) : (
                ""
              )}
              {this.props?.p2_filter_state.current == true &&
              this.props?.p2_filter_city.current == true &&
              this.props?.p2_filter_referrer.current == true &&
              this.props?.p2_filter_item.current == true ? (
                <td>
                  {this.props.p2_Data?.reduce(
                    (acc, x) => x.total_selected_ItemQty + acc,
                    0
                  )}
                </td>
              ) : (
                ""
              )}

              <td>
                {parseFloat(
                  this.props.p2_Data?.reduce(
                    (acc, x) => x.total_item_price_cost + acc,
                    0
                  )
                ).toFixed(2)}
              </td>
              <td>
                {parseFloat(
                  this.props.p2_Data?.reduce(
                    (acc, x) => x.total_item_price_srp + acc,
                    0
                  )
                ).toFixed(2)}
              </td>
              <td>
                {parseFloat(
                  this.props.p2_Data?.reduce(
                    (acc, x) => x.total_profit + acc,
                    0
                  )
                ).toFixed(2)}
              </td>
              <td>
                {parseFloat(
                  this.props.p2_Data?.reduce(
                    (acc, x) => x.total_payment_total_amount_paid + acc,
                    0
                  )
                ).toFixed(2)}
              </td>
            </tr>
          </table>
        </div>
        <div className="page-break" />
        {/* <div>
          <div>My cool content here! p2</div>
        </div>
        <div className="page-break" />
        <div>My cool content here! p3</div>
        <div className="page-break" />

        <div>My cool content here! p4</div> */}
      </div>
    );
  }
}

export default X;
