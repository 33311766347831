import React, { Component, useEffect, useState, useRef } from "react";

import moment from "moment";

import {
  DateRangePicker,
  RangeCalendar,
  DatePicker,
  Calendar,
} from "@mantine/dates";
import { Button } from "@mantine/core";

export default function COM_DateTimeMANTINE({
  mode,
  bg_color,
  p_onChange,
  p_value,
}) {
  // const [RC_value, setRC_Value] = useState([
  //   new Date("2021-03-01"),
  //   new Date("2022-04-01"),
  // ]);
  // const [N_value, setN_Value] = useState(new Date("2022-03-02"));

  return (
    <>
      <div style={{ backgroundColor: bg_color }}>
        {/* ------------------------------------- RangeCalendar*/}
        {mode == "range" ? (
          <>
            {console.log("html render ")}
            <RangeCalendar
              // label="Book hotel"
              // placeholder="Pick dates range"
              amountOfMonths={2}
              value={p_value}
              onChange={p_onChange}
              // onChange={(b4, af) => setValue(b4, af)}
              // onChange={(b4, af) => onChange2(b4, af)}
            />
            <br />
            <br />
            {/* {`SELECTED : ${moment(p_value[0]).format("YYYY-MM-DD")} - ${moment(
            p_value[1]
          ).format("YYYY-MM-DD")}`} */}
            <br />
            {/* <Button
            onClick={() => {
              console.log(p_value);
            }}
          >
            Get RC Props
          </Button> */}
          </>
        ) : (
          <>
            {/* ------------------------------------- Calendar*/}
            <Calendar
              value={p_value}
              onChange={p_onChange}
              // onChange={setValue}
              // value={p_value}
              // onChange={p_onChange}
              firstDayOfWeek="sunday"
            />

            <br />
            <br />
            {`SELECTED : ${moment(p_value).format("YYYY-MM-DD")}`}
            <br />
            <br />
            {/* <Button
            onClick={() => {
              console.log(p_value);
            }}
          >
            Get NC Props
          </Button> */}
          </>
        )}
      </div>
    </>
  );
}
