import React, { Component, useState, useReducer, useEffect } from "react";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Autocomplete,
} from "@mui/material";

export default function COM_ItemCart({ p_data, setParent_ItemCart }) {
  const [item, setItem] = useState();
  const [carts, dispatch] = useReducer(reducer, []);
  function reducer(_carts, _action) {
    if (_action.type === "add-todo") {
      let result;
      result = [..._carts, newTodo(_action.payload.item)];
      console.log("result - add-todo", result);
      setParent_ItemCart(result);
      return result;
    } else if (_action.type === "delete-todo") {
      let result;
      result = _carts.filter((todo) => todo.id !== _action.payload.id);
      console.log("result - delete-todo", result);
      setParent_ItemCart(result);
      return result;
    } else {
      console.log("result - etc", _carts);
      setParent_ItemCart(_carts);
      return _carts;
    }
  }

  function newTodo(_item) {
    return { id: Date.now(), item: _item, complete: false };
  }

  // add item
  const addItem = (_item) => {
    dispatch({ type: "add-todo", payload: { item: _item } });
    setItem("");
  };

  return (
    <>
      <div
        className="btn_normal"
        onClick={() => {
          // addItem({ item_id: 12, item_name: "maxsun", selected_ItemQty: 55 });
          addItem({
            item_id: p_data.item_id,
            item_name: p_data.item_name,

            item_desc: p_data.item_desc,
            item_price_cost: p_data.item_price_cost,
            item_price_srp: p_data.item_price_srp,
            item_gen_by: p_data.item_gen_by,

            selected_ItemPrice: p_data.selected_ItemPrice,
            selected_ItemQty: p_data.selected_ItemQty,
          });
        }}
      >
        {` Add to List (${carts.length} item(s))`}
      </div>
      {carts.map((carts) => {
        return (
          <Cart_Item
            key={carts.id}
            carts={carts}
            dispatch={dispatch}
            setParent_ItemCart={setParent_ItemCart}
          />
        );
      })}
    </>
  );
}

// child component
function Cart_Item({ carts, dispatch }) {
  return (
    <div className="global_grid_4column_container_custom001  ">
      {/* <div>{`${carts.id}`}</div> */}

      <div>{`[Item ID :${carts.item.item_id}]   ${carts.item.item_name}`}</div>
      <div>{carts.item.selected_ItemPrice}</div>
      <div>{carts.item.selected_ItemQty}</div>

      <Button
        color="secondary"
        onClick={() =>
          dispatch({ type: "delete-todo", payload: { id: carts.id } })
        }
      >
        Remove
      </Button>
    </div>
  );
}
