import React, {
  Component,
  useState,
  useReducer,
  useEffect,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import { AiFillEdit } from "react-icons/ai";

import {
  TextField,
  FormGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

//  -------------------------- Material Table
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";
import COM_NavBar_Order from "../Component/COM_NavBar_Order";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  // get data
  const [DB_Data_All, setDB_Data_All] = useState([]);
  const get_DB_Data_All = (_refererLists) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_vlerp__order_log__step3_isready_all`,
        {
          refererLists: _refererLists,
        }
      )
      .then((res) => {
        setDB_Data_All(res.data);
        console.log("# get_DB_Data_All", res.data);
      });
  };

  // get data - by order id
  const [DB_Data_byorderid, setDB_Data_byorderid] = useState([]);
  const get_DB_Data_byorderid = (_order_id) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_vlerp__order_log__step1and2and3_isready_byorderid`,
        {
          order_id: _order_id,
        }
      )
      .then((res) => {
        setDB_Data_byorderid(res.data);
        console.log("# get_DB_Data_byorderid", res.data);
      });
  };

  // update info & proceed to step
  const set_DB_Step2_to_Step3 = (_order_id, _step3_by) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/set_vlerp__order_log__step2_to_step3`, {
        order_id: _order_id,
        step3_by: _step3_by,
      })
      .then((res) => {
        if (res.data.affectedRows > 0) {
          alert("Update Info Successful !");
        } else {
          alert("Failed Info, Please contact Admin !");
        }
        get_DB_Data_All(JSON.parse(contextValue?.userInfo?.u_referer_list));

        console.log("# set_DB_Step2_to_Step3", res.data);
      });
  };

  // ------------------------------------------------- Table Columns

  const columns = [
    // {
    //   title: "Status",
    //   field: "ticket_status_desc",
    //   width: "1px",
    //   render: (rowData) => (
    //     <div
    //       className="btn_normal"
    //       aria-controls="simple-menu"
    //       aria-haspopup="true"
    //       style={{
    //         color: "#ffffff",
    //         backgroundColor:
    //           rowData.ticket_status_desc == "pending" ? "red" : "green",
    //       }}
    //       onClick={(e) => {
    //         // set_selectedRole_id(rowData.id);
    //         // handleClick_Menu(e);
    //         // setTimeout(() => {
    //         //   get_DB_ticket_record_all_unsolved(
    //         //     contextValue.current,
    //         //     false,
    //         //     false
    //         //   );
    //         // }, 2000);
    //       }}
    //     >
    //       {rowData.ticket_status_desc}
    //     </div>
    //   ),
    // },
    {
      title: "Order Id",
      field: "order_id",
      width: "1%",
      cellStyle: {
        backgroundColor: "rgb(250, 169, 94)",
        color: "#000",
      },
      headerStyle: {
        backgroundColor: "rgb(250, 169, 94)",
        color: "#000",
        maxWidth: "1px",
      },
    },
    {
      title: "Client Id",
      field: "client_id",
      maxWidth: "1px",
    },
    {
      title: "Client Name",
      field: "client_name",
      width: "300px",
    },
    {
      title: "Total Qty",
      field: "Total_selected_ItemQty",
      width: "1px",
    },
    {
      title: "Total Price (RM)",
      field: "Total_selected_ItemPrice",
      width: "1px",
    },
    {
      title: "Payment Status",
      field: "payment_status",
      width: "1px",
    },
    {
      title: "Courier Tracking No",
      field: "courier_tracing_no",
      width: "1px",
    },
    {
      title: "Courier Service",
      field: "courier_service",
      width: "1px",
    },
    {
      title: "Courier Note",
      field: "courier_note",
      width: "1px",
    },
    {
      title: "Gen At",
      field: "step1_dt",
      width: "1px",
    },
    {
      title: "Gen By",
      field: "step1_by",
      width: "1px",
    },
    {
      title: "Referer Id",
      field: "referer_id",
      width: "1px",
    },
  ];
  const columns_DB_Data_byorderid = [
    // {
    //   title: "Status",
    //   field: "ticket_status_desc",
    //   width: "1px",
    //   render: (rowData) => (
    //     <div
    //       className="btn_normal"
    //       aria-controls="simple-menu"
    //       aria-haspopup="true"
    //       style={{
    //         color: "#ffffff",
    //         backgroundColor:
    //           rowData.ticket_status_desc == "pending" ? "red" : "green",
    //       }}
    //       onClick={(e) => {
    //         // set_selectedRole_id(rowData.id);
    //         // handleClick_Menu(e);
    //         // setTimeout(() => {
    //         //   get_DB_ticket_record_all_unsolved(
    //         //     contextValue.current,
    //         //     false,
    //         //     false
    //         //   );
    //         // }, 2000);
    //       }}
    //     >
    //       {rowData.ticket_status_desc}
    //     </div>
    //   ),
    // },
    {
      title: "Order Id",
      field: "order_id",
      width: "1%",
      cellStyle: {
        backgroundColor: "rgb(250, 169, 94)",
        color: "#000",
      },
      headerStyle: {
        backgroundColor: "rgb(250, 169, 94)",
        color: "#000",
        maxWidth: "1px",
      },
    },
    {
      title: "Client Id",
      field: "client_id",
      maxWidth: "1px",
    },
    {
      title: "Client Name",
      field: "client_name",
      width: "300px",
    },
    {
      title: "Total Qty",
      field: "Total_selected_ItemQty",
      width: "1px",
    },
    {
      title: "Total Price (RM)",
      field: "Total_selected_ItemPrice",
      width: "1px",
    },
    {
      title: "Payment Status",
      field: "payment_status",
      width: "1px",
    },
    {
      title: "Courier Tracking No",
      field: "courier_tracing_no",
      width: "1px",
    },
    {
      title: "Courier Service",
      field: "courier_service",
      width: "1px",
    },
    {
      title: "Courier Note",
      field: "courier_note",
      width: "1px",
    },
    {
      title: "Gen At",
      field: "step1_dt",
      width: "1px",
    },
    {
      title: "Gen By",
      field: "step1_by",
      width: "1px",
    },
  ];

  // --------------------------------------- dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  const hd_DialogOpen = () => {
    setDialogOpen(true);
  };

  const hd_DialogClose = () => {
    setDialogOpen(false);
  };
  // --------------------------------------- global variable
  const selected_Order_Id = useRef("NA");

  const selected_TrackingNo = useRef("NA");
  const selected_TrackingNotes = useRef("NA");

  const [AC_SelectedData_CourierService, setAC_SelectedData_CourierService] =
    useState([]);

  // ---------------------------------- useEffect
  useEffect(() => {
    get_DB_Data_All(JSON.parse(contextValue?.userInfo?.u_referer_list));
  }, []);

  return (
    <>
      <COM_NavBar_Global p_Selected_Button="2" />
      <COM_NavBar_Order p_Selected_Button="3" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div>{`User Lvl : ${contextValue?.userInfo?.u_role_lvl}`}</div>
      <div>{`User Role : ${contextValue?.userInfo?.u_role}`}</div>
      <div>{`User Referer List : ${JSON.parse(
        contextValue?.userInfo?.u_referer_list
      )?.map((x) => x)}`}</div>
      <br />
      <h1>3.iii. Order Complate</h1>
      <MaterialTable
        style={{
          MuiTablePaginationRoot: "gold",
          color: "black",
          // background: "rgba(0, 0, 100, .0)",
          backgroundImage:
            "linear-gradient(to bottom right, rgb(255, 255, 255), rgb(255, 255, 255))",
          boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
          backdropFilter: "blur( 11.0px )",
          "&::webkit-backdrop-filter": {
            webkitBackdropFilter: "blur( 18.0px )",
          },
        }}
        title="3.iii Order Complate"
        columns={columns}
        data={DB_Data_All}
        options={{
          headerStyle: {
            background: "rgba(255, 255, 255, .2)",
            border: "none",
          },
          exportButton: true,
          rowStyle: (rowData) => ({
            // backgroundColor: "#1a0000",
            // color: "#ffffff",
            fontSize: 12,
          }),
          filtering: true,
          // grouping: true,
          // exportAllData: true,
          pageSize: 10,
          pageSizeOptions: [20, 50, 100],
          paginationType: "stepped",
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
          // exportMenu: [
          //   {
          //     label: "Export PDF",
          //     exportFunc: (cols, datas) =>
          //       ExportPdf(cols, datas, "myPdfFileName"),
          //   },
          //   {
          //     label: "Export CSV",
          //     exportFunc: (cols, datas) =>
          //       ExportCsv(cols, datas, "myCsvFileName"),
          //   },
          // ],
        }}
        // onRowClick={(evt, selectedRow) => {
        //   //
        // }}

        actions={[
          {
            icon: "save",
            tooltip: "Save User",
            onClick: (event, selectedRow) => {
              selected_Order_Id.current = selectedRow.order_id;
              get_DB_Data_byorderid(selectedRow.order_id);
              hd_DialogOpen();
            },
          },
        ]}
        components={{
          Action: (props) => (
            <>
              <div
                className="btn_edit "
                onClick={(event) => props.action.onClick(event, props.data)}
              >
                <AiFillEdit style={{ transform: "scale(2)" }} />
              </div>
            </>
          ),
        }}
      />
      <Dialog
        // onBackdropClick="false"
        // disableEscapeKeyDown
        fullScreen
        fullWidth={true}
        maxWidth="xl"
        open={dialogOpen}
        onClose={hd_DialogClose}
        style={
          {
            // background: "rgba(255, 195, 113, 1)",
            // backdropFilter: "blur( 5.0px )",
          }
        }
        PaperProps={{
          style: {
            // background: "rgba(255, 195, 113, .0)",
            // boxShadow: "0 0",
          },
        }}
      >
        <DialogTitle>
          {`3.iii.a Order Complete (Order Id : ${selected_Order_Id.current})`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <div
            className="btn_normal"
            onClick={() => {
              alert("soon");
            }}
          >
            Download SVC (Pandding)
          </div>
          <br />
          <br />
          <MaterialTable
            style={{
              MuiTablePaginationRoot: "gold",
              color: "black",
              // background: "rgba(0, 0, 100, .0)",
              backgroundImage:
                "linear-gradient(to bottom right, rgb(255, 255, 255), rgb(255, 255, 255))",
              boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
              backdropFilter: "blur( 11.0px )",
              "&::webkit-backdrop-filter": {
                webkitBackdropFilter: "blur( 18.0px )",
              },
            }}
            title="3.iii.a Order Complete - Details "
            columns={columns_DB_Data_byorderid}
            data={DB_Data_byorderid}
            options={{
              headerStyle: {
                background: "rgba(255, 255, 255, .2)",
                border: "none",
              },
              exportButton: true,
              rowStyle: (rowData) => ({
                // backgroundColor: "#1a0000",
                // color: "#ffffff",
                fontSize: 12,
              }),
              filtering: true,
              // grouping: true,
              // exportAllData: true,
              pageSize: 5,
              pageSizeOptions: [10, 20, 50, 100],
              paginationType: "stepped",
              // actionsColumnIndex: -1
              // fixedColumns: {
              //     left: 1,
              // }
              // exportMenu: [
              //   {
              //     label: "Export PDF",
              //     exportFunc: (cols, datas) =>
              //       ExportPdf(cols, datas, "myPdfFileName"),
              //   },
              //   {
              //     label: "Export CSV",
              //     exportFunc: (cols, datas) =>
              //       ExportCsv(cols, datas, "myCsvFileName"),
              //   },
              // ],
            }}
            // onRowClick={(evt, selectedRow) => {
            //   //
            // }}
            // actions={[
            //   {
            //     icon: "save",
            //     tooltip: "Save User",
            //     onClick: (event, selectedRow) => {
            //       selected_Order_Id.current = selectedRow.order_id;
            //       hd_DialogOpen();
            //     },
            //   },
            // ]}
            // components={{
            //   Action: (props) => (
            //     <>
            //       <div
            //         className="btn_edit "
            //         onClick={(event) => props.action.onClick(event, props.data)}
            //       >
            //         <AiFillEdit style={{ transform: "scale(2)" }} />
            //       </div>
            //     </>
            //   ),
            // }}
          />
        </DialogContent>
        <DialogActions>
          {/* <div
            className="btn_normal"
            onClick={() => {
              set_DB_Step2_to_Step3(
                selected_Order_Id.current,
                contextValue.current.firebase_userInfo.email
              );

              hd_DialogClose();
            }}
          >
            Confirm Proceed
          </div> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
