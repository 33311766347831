import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import { FcGoogle as GoogleIcon } from "react-icons/fc";
import { AiFillMail } from "react-icons/ai";
import { FaFacebookSquare as FacebookIcon } from "react-icons/fa";
// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_Login({ parent_fakeAuth, parrent_DB_userinfo }) {
  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});
  const [firebase_user_SignedIn, setFirebase_user_SignedIn] = useState(false);
  const [DB_user_Authenticated, setDB_user_Authenticated] = useState(false);

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);

    if (authentication?.currentUser != null) {
      setFirebase_user_SignedIn(true);
      // console.log("# user Signed in", firebase_user_SignedIn.current);
    } else {
      setFirebase_user_SignedIn(false);
      // console.log("# user Signed in", firebase_user_SignedIn.current);
    }
  });

  const singInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        console.log("g re", re);
      })
      .catch((err) => {
        console.log("g re-err", err);
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        console.log("f re", re);
      })
      .catch((err) => {
        console.log("f re-err", err);
      });
  };

  // ------------------------------------------------- DB

  // get user info by uid
  const [DB_users, setDB_users] = useState([]);
  const get_DB_users_List = () => {
    // console.log("S1");
    // console.log("firebase_userInfo.uid", firebase_userInfo.uid);
    // console.log(
    //   "providerData.providerId",
    //   firebase_userInfo.providerData[0]?.providerId
    // );
    // console.log("providerData.uid", firebase_userInfo.providerData[0]?.uid);
    // console.log(
    //   "providerData.displayName",
    //   firebase_userInfo.providerData[0]?.displayName
    // );
    // console.log("providerData.email", firebase_userInfo.providerData[0]?.email);
    // console.log(
    //   "providerData.photoURL",
    //   firebase_userInfo.providerData[0]?.photoURL
    // );

    // assign to useContext
    contextValue.current = { firebase_userInfo: firebase_userInfo };

    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__userinfo_byuuid`, {
        u_uid: firebase_userInfo.uid,
      })
      .then((res) => {
        setDB_users(res.data[0]);
        console.log("# get_DB_users_List", res.data[0]);
        contextValue.userInfo = res.data[0];

        if (res.data[0] != undefined) {
          // user found in bd
          if (res.data[0].u_role_lvl <= 4) {
            // user is Authenticated
            parent_fakeAuth.isAuthenticated = true;
            parrent_DB_userinfo.currentDBUser = res.data[0];

            setDB_user_Authenticated(true);

            console.log("# window.location", window.location);
            if (
              window.location.toString().split("/#/")[1] == "" &&
              parent_fakeAuth.isAuthenticated
            ) {
              window.location.href = "#/main";
            }
          } else {
          }
        } else {
          // add un-Auth User to db
          console.log("Not Auth user, Add to list");
          add_DB_unAuth_users();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // add un-auth user to db
  const add_DB_unAuth_users = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/add_vlerp__userinfo`, {
        u_uid: firebase_userInfo.uid,
        u_name: firebase_userInfo.displayName,
        u_email: firebase_userInfo.email,
        u_providerId: firebase_userInfo.providerData[0]?.providerId,
        u_firebase_obj: JSON.stringify(firebase_userInfo),
      })
      .then((res) => {
        console.log("# add_DB_unAuth_users", res.data);
      });
  };

  // if user Signed in Firebase Auth
  useEffect(() => {
    if (firebase_user_SignedIn) {
      console.log("# firebase_user_SignedIn", firebase_user_SignedIn);

      // auth with DB users
      get_DB_users_List();

      console.log("# firebase_userInfo", firebase_userInfo);
    }
  }, [firebase_user_SignedIn]);

  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  return (
    <div className="pg_login_bg global_flex_column_cc">
      <div className="pg_login_window global_flex_column_cc">
        <div className="title">
          <h1>VLERP</h1>
        </div>
        <div className="sysinfo">
          {"firebase_user_SignedIn : " + firebase_user_SignedIn}
          <br />
          {"DB_user_Authenticated : " + DB_user_Authenticated}
        </div>
        {!firebase_user_SignedIn ? (
          <>
            <div
              className="btn_normal global_flex_row_cc"
              onClick={() => {
                singInWithGoogle();
              }}
            >
              <GoogleIcon
                style={{ padding: "0px 10px 0px 0px", fontSize: "30px" }}
              />
              <div>Login with Google</div>
            </div>

            <div
              className="btn_normal global_flex_row_cc"
              onClick={() => {
                signInWithFacebook();
              }}
            >
              <FacebookIcon
                style={{
                  color: "#4064AC",
                  padding: "0px 10px 0px 0px",
                  fontSize: "30px",
                }}
              />
              <div>Login with Facebook</div>
            </div>
          </>
        ) : (
          <>
            <div className="global_flex_column_cc">
              <img
                className="img_UserProfile_large"
                src={`${firebase_userInfo?.photoURL}`}
              ></img>
              <div className="userProfileText1">
                {`${firebase_userInfo?.displayName}`}
              </div>
              <div className="userProfileText2">
                {`${firebase_userInfo?.email}`}
              </div>
              <div className="provider">
                {`${
                  firebase_userInfo?.providerData[0]?.providerId == "google.com"
                    ? firebase_userInfo?.email
                    : firebase_userInfo?.providerData[0]?.email
                }`}
              </div>
            </div>

            <div
              className="btn_warning global_flex_row_cc"
              onClick={() => {
                signOut(authentication);
              }}
            >
              LOGOUT
            </div>
          </>
        )}
      </div>
    </div>
  );
}
