import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import COM_AutoComplete_Referer from "../Component/COM_AutoComplete_Referer";

import { AiFillEdit } from "react-icons/ai";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";

//  -------------------------- Material Table
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";
import COM_NavBar_User from "../Component/COM_NavBar_User";

import Print_Report_Sales_Monthly from "../Print/Print_Report_Sales_Monthly";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  //
  const [DB_RefererList, setDB_RefererList] = useState([]);
  const get_DB_Referer_List = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__referer_info__all`)
      .then((res) => {
        setDB_RefererList(res.data);
        console.log("get_DB_Referer_List", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const [DB_Users, setDB_Users] = useState([]);
  const get_DB_Users = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__users__all`)
      .then((res) => {
        setDB_Users(res.data);
        console.log("get_DB_Users", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const add_DB_Referer_List = (
    _referer_name,
    _referer_role,

    _referer_contact,
    _referer_address,
    _referer_gen_by,
    _referer_gen_uid,
    _referer_gen_pic
  ) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/add_vlerp__referer_info`, {
        referer_name: String(_referer_name).replace("'", "`"),
        referer_role: String("Et 9999").replace("'", "`"),

        referer_contact: String(_referer_contact).replace("'", "`"),
        referer_address: String(_referer_address).replace("'", "`"),
        referer_gen_by: String(_referer_gen_by).replace("'", "`"),
        referer_gen_uid: String(_referer_gen_uid),
        referer_gen_pic: String(_referer_gen_pic),
      })
      .then((res) => {
        setDB_RefererList(res.data);
        console.log("add_DB_Referer_List", res.data);
        if (res.data.affectedRows > 0) {
          alert("New Referer Add Successfully ! ");

          setTimeout(() => {
            get_DB_Referer_List();
            hd_DialogClose();
          }, 2000);
        } else {
          alert("Failed to Add New Referer, Please contact Admin !");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // update user role
  const set_DB_Users_Role = (
    _u_role,
    _u_uid,
    _auth_by,
    _auth_by_uuid,
    _auth_by_pic,
    _u_referer_obj
  ) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/set_vlerp__users__role_byuuid`, {
        u_role: _u_role,
        u_uid: _u_uid,
        auth_by: _auth_by,
        auth_by_uuid: _auth_by_uuid,
        auth_by_pic: _auth_by_pic,
        u_referer_obj:
          _u_referer_obj != "" ? JSON.stringify(_u_referer_obj) : "",
      })
      .then((res) => {
        if (res.data.affectedRows > 0) {
          alert("SUCESSFULY Update!");
        } else {
          alert("FAILED update, please contact admin");
        }
        console.log("set_DB_Users_Role", res.data);
        get_DB_Users();
        setAC_SelectedData_Referer("");
      })
      .catch((err) => {
        setAC_SelectedData_Referer("");
        console.log("err", err);
      });
  };

  // ------------------------------------------------- etc

  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      referer_name: "",
      referer_contact: "",
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    referer_name: "",
    referer_contact: "",
  });

  const handleSubmit = (event) => {
    console.log("hit");
    event.preventDefault();
    setValue({
      referer_id: selected_RefererID.current,
      referer_name: dialogValue.referer_name,
      referer_contact: dialogValue.referer_contact,
      referer_address: dialogValue.referer_address,
    });

    // // update parent
    setAC_SelectedData_Referer({
      referer_name: dialogValue.referer_name,
      referer_contact: dialogValue.referer_contact,
      referer_address: "dialogValue.referer_address",
    });

    // add to db
    add_DB_Referer_List(
      dialogValue.referer_name,
      Selected_RoleValue,

      dialogValue.referer_contact,
      dialogValue.referer_address,
      contextValue.current.firebase_userInfo.displayName,
      contextValue.current.firebase_userInfo.uid,
      contextValue.current.firebase_userInfo.providerData[0]?.photoURL
    );
  };

  // ------------------------------------------------- Table Columns
  const columns = [
    {
      title: "Referer Id",
      field: "id",
      width: "1%",
      cellStyle: {
        backgroundColor: "rgba(0, 255, 255, 0.5)",
        color: "#000",
      },
      headerStyle: {
        backgroundColor: "rgba(0, 255, 255, 0.5)",
        color: "#000",
        maxWidth: "1px",
      },
    },
    {
      title: "Referer Name",
      field: "referer_name",
      width: "1%",
    },
    {
      title: "Referer Role",
      field: "referer_role",
      width: "1%",
      render: (rowData) => (
        <>{rowData.referer_role.toString().replaceAll("_", " ")}</>
      ),
    },
    {
      title: "Referer Contact",
      field: "referer_contact",
      width: "1%",
    },
    {
      title: "Referer Address",
      field: "referer_address",
      width: "1%",
    },
    // , , , , , ,  , ,
    {
      title: "Gen at",
      field: "referer_gen_dt",
      // width: "1px",
    },
    {
      title: "Gen by",
      // field: " ",
      minWidth: "10rem",
      render: (rowData) => (
        <>
          <div className="global_flex_col_cc global_flex_alignItems_Left">
            <img
              className="img_UserProfile_small"
              src={rowData.referer_gen_pic}
            />
            <div className="">
              <b>{rowData.referer_gen_by}</b>
            </div>
          </div>
        </>
      ),
    },
  ];
  // --------------------------------------- dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  const hd_DialogOpen = () => {
    setDialogOpen(true);
  };

  const hd_DialogClose = () => {
    setDialogOpen(false);
  };
  const [dialogOpen2, setDialogOpen2] = useState(false);

  const hd_DialogOpen2 = () => {
    setDialogOpen2(true);
  };

  const hd_DialogClose2 = () => {
    setDialogOpen2(false);
  };

  // --------------------------------------- radio button
  const [rb_Value, setRb_Value] = React.useState("");

  const hc_Rb_Value = (event) => {
    setRb_Value(event.target.value);
  };

  // --------------------------------------- global variable
  const selected_User_UUID = useRef("NA");

  const selectedTable_RowData = useRef([]);

  const [AC_SelectedData_Referer, setAC_SelectedData_Referer] = useState([]);

  const selected_RefererID = useRef("NA");

  // ------------------------------------------------- Radio

  const [Selected_RoleValue, setSelected_RoleValue] = React.useState("CS");

  const hc_Selected_RoleValue = (event) => {
    setSelected_RoleValue(event.target.value);
  };

  // ------------------------------------------------- cust component
  const [refererOwnners, setRefererOwnners] = useState([]);
  const findRefererOwnners = (_systemUserId) => {
    console.log("findRefererOwnner", _systemUserId);
    let refererOwnners = DB_Users.filter((x) => {
      let result = "";
      if (
        x.u_referer_list != null &&
        x.u_referer_list != undefined &&
        x.u_referer_list != ""
      ) {
        result = JSON.parse(x.u_referer_list.toString());
      }

      return result.includes(_systemUserId.toString());
    });

    console.log("refererOwnners", refererOwnners);
    setRefererOwnners(refererOwnners);
  };

  // ------------------------------------------------- useEffect

  useEffect(() => {
    get_DB_Users();
    get_DB_Referer_List();
  }, []);
  return (
    <>
      <COM_NavBar_Global p_Selected_Button="5" />
      <COM_NavBar_User p_Selected_Button="2" />
      <h1>5.ii. Manage Referer </h1>
      <br />
      <div
        className="btn_normal"
        onClick={() => {
          hd_DialogOpen2();
        }}
      >
        Create New Referer
      </div>
      <br />
      <br />
      <MaterialTable
        style={{
          MuiTablePaginationRoot: "gold",
          color: "black",
          // background: "rgba(0, 0, 100, .0)",
          backgroundImage:
            "linear-gradient(to bottom right, rgb(255, 255, 255), rgb(255, 255, 255))",
          boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
          backdropFilter: "blur( 11.0px )",
          "&::webkit-backdrop-filter": {
            webkitBackdropFilter: "blur( 18.0px )",
          },
        }}
        title={
          <div className="global_flex_row_cc">
            <h1>5.ii. Manage Referer</h1>
            <Button
              onClick={() => {
                get_DB_Users();
                get_DB_Referer_List();
              }}
            >
              <ReplayIcon />
              Reload Data
            </Button>
          </div>
        }
        columns={columns}
        data={DB_RefererList}
        options={{
          headerStyle: {
            background: "rgba(255, 255, 255, .2)",
            border: "none",
          },
          exportButton: true,
          rowStyle: (rowData) => ({
            // backgroundColor: "#1a0000",
            // color: "#ffffff",
            fontSize: 12,
          }),
          filtering: true,
          // grouping: true,
          // exportAllData: true,
          pageSize: 10,
          pageSizeOptions: [20, 50, 100],
          paginationType: "stepped",
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
          // exportMenu: [
          //   {
          //     label: "Export PDF",
          //     exportFunc: (cols, datas) =>
          //       ExportPdf(cols, datas, "myPdfFileName"),
          //   },
          //   {
          //     label: "Export CSV",
          //     exportFunc: (cols, datas) =>
          //       ExportCsv(cols, datas, "myCsvFileName"),
          //   },
          // ],
        }}
        // onRowClick={(evt, selectedRow) => {
        //  //
        // }}
        actions={[
          {
            icon: "save",
            tooltip: "Save User",
            onClick: (event, selectedRow) => {
              selectedTable_RowData.current = selectedRow;

              setRb_Value(selectedRow?.u_role);

              findRefererOwnners(selectedRow?.id);

              setTimeout(() => {
                hd_DialogOpen();
              }, 1000);
            },
          },
        ]}
        components={{
          Action: (props) => (
            <>
              <div
                className="btn_edit "
                onClick={(event) => props.action.onClick(event, props.data)}
              >
                <AiFillEdit style={{ transform: "scale(2)" }} />
              </div>
            </>
          ),
        }}
      />

      <Dialog
        // onBackdropClick="false"
        // disableEscapeKeyDown
        fullScreen
        fullWidth={true}
        maxWidth="xl"
        open={dialogOpen}
        onClose={hd_DialogClose}
        style={
          {
            // background: "rgba(255, 195, 113, 1)",
            // backdropFilter: "blur( 5.0px )",
          }
        }
        PaperProps={{
          style: {
            // background: "rgba(255, 195, 113, .0)",
            // boxShadow: "0 0",
          },
        }}
      >
        <DialogTitle>{`5.ii.a Referer - details`}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <div className="global_paper_lightGray">
            <h2>Referer Id</h2>
            {selectedTable_RowData?.current.id}
            <h2>Referer Name</h2>
            {selectedTable_RowData?.current.referer_name}
            <h2>Referer Contact</h2>
            {selectedTable_RowData?.current.referer_contact}
            <h2>Referer Address</h2>
            {selectedTable_RowData?.current.referer_address}
            <h2>Referer Register at</h2>
            {selectedTable_RowData?.current.referer_gen_dt}
          </div>
          <br />
          <br />
          <div className="global_paper_lightGray">
            <h2>Owned by System Users</h2>

            {refererOwnners.map((x) => (
              <>
                <div className="global_flex_row_cc global_flex_alignItems_Left margin_20 ">
                  <img
                    className="img_UserProfile_small"
                    src={`${JSON.parse(x.u_firebase_obj).photoURL}`}
                  />
                  <div className="global_flex_col_cc global_flex_alignItems_Left padding_10">
                    <div>{`SysUserId : ${x.id} , ${x.u_name}`}</div>

                    <div>{x.u_role.replaceAll("_", " ")}</div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <div
            className="btn_normal  "
            onClick={() => {
              hd_DialogClose();
            }}
          >
            <div className="padding_30">Cancel</div>
          </div>

          {/* <div
            className="btn_normal "
            onClick={() => {
             
              set_DB_Users_Role(
                rb_Value,
                selectedTable_RowData.current.u_uid,
                contextValue.current.firebase_userInfo.displayName,
                contextValue.current.firebase_userInfo.uid,
                contextValue.current.firebase_userInfo.photoURL,
                AC_SelectedData_Referer
              );
              hd_DialogClose();
            }}
          >
            <div className="padding_30">Confirm</div>
          </div> */}
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen2}
        onClose={hd_DialogClose2}
        // fullScreen
        fullWidth={true}
        maxWidth="xl"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new Referer</DialogTitle>
          <DialogContent>
            <DialogContentText>..</DialogContentText>

            <div className="global_flex_column_cc gap_50">
              <TextField
                fullWidth
                autoFocus
                margin="dense"
                id="name"
                value={dialogValue.referer_name}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    referer_name: event.target.value,
                  })
                }
                label="Referer Name"
                type="text"
                variant="standard"
              />

              {/* <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={Selected_RoleValue}
                onChange={hc_Selected_RoleValue}
              >
                <FormControlLabel
                  value="Admin"
                  control={<Radio />}
                  label="Lv0 : Admin"
                />
                <FormControlLabel
                  value="Brand_Partner"
                  control={<Radio />}
                  label="Lv1 : Brand Partner"
                />
                <FormControlLabel
                  value="Brand_Partner__Customer_Service"
                  control={<Radio />}
                  label="Lv2 : Brand Partner Customer Service"
                />
                <FormControlLabel
                  value="Agent"
                  control={<Radio />}
                  label="Lv3 : Agent"
                />
                <FormControlLabel
                  value="Agent_Customer__Service"
                  control={<Radio />}
                  label="Lv4 : Agent Customer Service"
                />
                <FormControlLabel
                  value="Banned_User"
                  control={<Radio />}
                  label="Banned User"
                />
              </RadioGroup> */}
              <TextField
                fullWidth
                margin="dense"
                id="name"
                value={dialogValue.referer_contact}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    referer_contact: event.target.value,
                  })
                }
                label="Referer Contact"
                type="text"
                variant="standard"
              />
              <TextField
                fullWidth
                margin="dense"
                id="name"
                value={dialogValue.referer_address}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    referer_address: event.target.value,
                  })
                }
                label="Referer Addtess"
                type="text"
                variant="standard"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
