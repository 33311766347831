import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import {
  RadioGroup,
  Radio,
  Slider,
  RangeSlider,
  CheckboxGroup,
  Checkbox,
} from "@mantine/core";
import { Calendar } from "@mantine/dates";

import MUI_Slider from "@mui/material/Slider";

export default function COM_DatetimeSelector_Custom({
  p_selected_Year_Range,
  p_setSelected_Year_Range,
  p_selected_Month_Start,
  p_setSelected_Month_Start,
  p_selected_Month_End,
  p_setSelected_Month_End,
}) {
  // ---------------------------------------------------- Slider
  // const [selected_Year, setSelected_Year] = useState([22, 22]);

  const marks_Years = [
    { value: 20, label: "2020" },
    { value: 21, label: "2021" },
    { value: 22, label: "2022" },
    // { value: 23, label: "2023" },
    // { value: 24, label: "2024" },
    // { value: 25, label: "2025" },
    // { value: 26, label: "2026" },
    // { value: 27, label: "2027" },
    // { value: 28, label: "2028" },
    // { value: 29, label: "2029" },
    // { value: 30, label: "2030" },
  ];

  const MARKS = [
    { value: 1, label: <div className="rorate_90d">JAN</div> },
    { value: 2, label: <div className="rorate_90d">FEB</div> },
    { value: 3, label: <div className="rorate_90d">MAR</div> },
    { value: 4, label: <div className="rorate_90d">APR</div> },
    { value: 5, label: <div className="rorate_90d">MAY</div> },
    { value: 6, label: <div className="rorate_90d">JUNE</div> },
    { value: 7, label: <div className="rorate_90d">JULY</div> },
    { value: 8, label: <div className="rorate_90d">AUG</div> },
    { value: 9, label: <div className="rorate_90d">SEP</div> },
    { value: 10, label: <div className="rorate_90d">OTC</div> },
    { value: 11, label: <div className="rorate_90d">NOV</div> },
    { value: 12, label: <div className="rorate_90d">DEC</div> },
  ];

  useEffect(() => {
    // console.log("useEffect");
  }, []);

  return (
    <div>
      <>
        <div className="global_paper_lightGray">
          {/* //-------------------------------------------------- 1. Select Year */}
          {/* {console.log("selected_Year", p_selected_Year_Range)} */}
          <h1>{`1. Year : 20${p_selected_Year_Range[0]}-20${p_selected_Year_Range[1]}`}</h1>
          <div className="margin_20">
            <RangeSlider
              label={`20${p_selected_Year_Range[0]}-20${p_selected_Year_Range[1]}`}
              value={p_selected_Year_Range}
              onChange={(e) => {
                p_setSelected_Year_Range(e);
                p_setSelected_Year_Range(e);
              }}
              // defaultValue={[2.0, 3.0]}
              // precision={}
              // step={1}
              size="xl"
              minRange={0}
              min={20}
              max={22}
              marks={marks_Years}
            />
            <div className="global_Text_Tips">
              Tips: Drag the Button to put into Year in range{" "}
            </div>
          </div>
          {/* //-------------------------------------------------- 2. Select Month Start */}
          <h1>2. Month</h1>
          <div className="global_flex_row_cc">
            <div className="global_flex_column_cc margin_20 width_100p">
              {/* {console.log("p_selected_Month_Start", p_selected_Month_Start)} */}
              <h2>{`from 20${p_selected_Year_Range[0]} - ${p_selected_Month_Start}`}</h2>
              <div className="width_100p">
                <MUI_Slider
                  value={p_selected_Month_Start}
                  onChange={(e, newValue) => {
                    p_setSelected_Month_Start(newValue);
                  }}
                  track="inverted"
                  aria-labelledby="track-inverted-range-slider"
                  defaultValue={1}
                  min={1}
                  max={12}
                  marks={MARKS}
                />
              </div>
            </div>
            <div className="global_flex_column_cc margin_20 width_100p">
              {/* //-------------------------------------------------- 2. Select Month End */}
              {/* {console.log("p_selected_Month_End", p_selected_Month_End)} */}
              <h2>{`until 20${p_selected_Year_Range[1]} - ${p_selected_Month_End}`}</h2>
              <div className="width_100p">
                <MUI_Slider
                  value={p_selected_Month_End}
                  onChange={(e, newValue) => {
                    p_setSelected_Month_End(newValue);
                  }}
                  // track="inverted"
                  aria-labelledby="track-inverted-range-slider"
                  defaultValue={12}
                  min={1}
                  max={12}
                  marks={MARKS}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
