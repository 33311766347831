import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import Chart_Pie from "../Chart/Chart_Pie";
import Chart_Line from "../Chart/Chart_Line";

// import { ComponentToPrint } from "./ComponentToPrint";

const X = ({ p_Data, p_Title }) => {
  const componentRef = useRef();

  // convert to chart data
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    console.log("# X - p_Data", p_Data);

    // 1. remove dulicate ID
    let unique_ID = [
      ...new Map(p_Data.map((item) => [item["paid_date_year"], item])).values(),
    ];
    // console.log("#useEffect - unique_ID", unique_ID);

    // 2. Merge with XY data
    let result = unique_ID.map((item) => {
      let selected_year = item?.paid_date?.split("-")[0];
      return {
        id: item?.paid_date?.split("-")[0],
        data: p_Data
          .filter((date) => {
            return date?.paid_date?.includes(selected_year);
          })
          .map((z) => {
            if (z?.paid_date?.split("-")[1] == "01") {
              return {
                x: "Jan",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            if (z?.paid_date?.split("-")[1] == "02") {
              return {
                x: "Feb",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            if (z?.paid_date?.split("-")[1] == "03") {
              return {
                x: "Mar",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            if (z?.paid_date?.split("-")[1] == "04") {
              return {
                x: "Apr",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            if (z?.paid_date?.split("-")[1] == "05") {
              return {
                x: "May",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            if (z?.paid_date?.split("-")[1] == "06") {
              return {
                x: "Jun",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            if (z?.paid_date?.split("-")[1] == "07") {
              return {
                x: "July",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            if (z?.paid_date?.split("-")[1] == "08") {
              return {
                x: "Aug",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            if (z?.paid_date?.split("-")[1] == "09") {
              return {
                x: "Sep",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            if (z?.paid_date?.split("-")[1] == "10") {
              return {
                x: "Oct",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            if (z?.paid_date?.split("-")[1] == "11") {
              return {
                x: "Nov",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            if (z?.paid_date?.split("-")[1] == "12") {
              return {
                x: "Dec",
                y: z.paid_total_amount != undefined ? z.paid_total_amount : 0,
              };
            }
            // return {
            //   x: z.paid_date,
            //   y: z.paid_total_amount,
            // };
          }),
      };
    });
    // console.log("#useEffect - result", result);

    // let result1 = p_Data.map((z) => {
    //   return {
    //     x: z.paid_date,
    //     y: z.paid_total_amount,
    //   };
    // });
    // console.log("#useEffect - result1", result1);

    // let result2 = [
    //   {
    //     id: "Title A",
    //     data: result1,
    //   },
    // ];
    // console.log("#useEffect - result2", result2);
    setChartData(result);
  }, [p_Data]);

  return (
    <div>
      <div className="pr__printBtn_Title">
        <ReactToPrint
          trigger={() => (
            <button className="btn_print ">
              <div className="padding_30">Print</div>
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div className="pr__page_display_printPage">
        <ComponentToPrint
          ref={componentRef}
          p2_Data={p_Data}
          p_chartData={chartData}
          p_Title={p_Title}
        />
      </div>
    </div>
  );
};

const pageStyle = () => {
  return `
  
`;
};

// ------------------------------------------------------  printing content
class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // c_Data: props.p_Data,
    };
  }

  componentDidMount = () => {
    console.log("p2_Data", this.props.p2_Data);
  };

  render() {
    const HeaderTitle = () => {
      return (
        <>
          <div className="pr__title_container">
            <img
              className="pr__title_logo"
              src={`https://eclipseapi3.freemyip.com/uploads/EclipseMy_Web/Company_Logo.png`}
            />

            <div>
              <div className="pr__title1">{this.props.p_Title} </div>
              <div className="pr__title2">
                {`${this.props.p2_Data?.at(0)?.paid_date} - ${
                  this.props.p2_Data?.at(-1)?.paid_date
                }`}
              </div>
            </div>
          </div>
        </>
      );
    };

    // const nivo_dbData = [
    //   {
    //     id: "japan2",
    //     color: "hsl(0, 70%, 50%)",
    //     data: [
    //       {
    //         x: "plane",
    //         y: 279,
    //       },
    //       {
    //         x: "helicopter",
    //         y: 226,
    //       },
    //       {
    //         x: "boat",
    //         y: 281,
    //       },
    //       {
    //         x: "train",
    //         y: 60,
    //       },
    //       {
    //         x: "subway",
    //         y: 95,
    //       },
    //       {
    //         x: "bus",
    //         y: 218,
    //       },
    //       {
    //         x: "car",
    //         y: 264,
    //       },
    //       {
    //         x: "moto",
    //         y: 68,
    //       },
    //       {
    //         x: "bicycle",
    //         y: 14,
    //       },
    //       {
    //         x: "horse",
    //         y: 175,
    //       },
    //       {
    //         x: "skateboard",
    //         y: 18,
    //       },
    //       {
    //         x: "others",
    //         y: 219,
    //       },
    //     ],
    //   },
    //   {
    //     id: "france",
    //     color: "hsl(17, 70%, 50%)",
    //     data: [
    //       {
    //         x: "plane",
    //         y: 254,
    //       },
    //       {
    //         x: "helicopter",
    //         y: 140,
    //       },
    //       {
    //         x: "boat",
    //         y: 100,
    //       },
    //       {
    //         x: "train",
    //         y: 107,
    //       },
    //       {
    //         x: "subway",
    //         y: 264,
    //       },
    //       {
    //         x: "bus",
    //         y: 217,
    //       },
    //       {
    //         x: "car",
    //         y: 30,
    //       },
    //       {
    //         x: "moto",
    //         y: 188,
    //       },
    //       {
    //         x: "bicycle",
    //         y: 135,
    //       },
    //       {
    //         x: "horse",
    //         y: 264,
    //       },
    //       {
    //         x: "skateboard",
    //         y: 5,
    //       },
    //       {
    //         x: "others",
    //         y: 53,
    //       },
    //     ],
    //   },
    //   {
    //     id: "us",
    //     color: "hsl(128, 70%, 50%)",
    //     data: [
    //       {
    //         x: "plane",
    //         y: 251,
    //       },
    //       {
    //         x: "helicopter",
    //         y: 250,
    //       },
    //       {
    //         x: "boat",
    //         y: 211,
    //       },
    //       {
    //         x: "train",
    //         y: 146,
    //       },
    //       {
    //         x: "subway",
    //         y: 218,
    //       },
    //       {
    //         x: "bus",
    //         y: 131,
    //       },
    //       {
    //         x: "car",
    //         y: 106,
    //       },
    //       {
    //         x: "moto",
    //         y: 83,
    //       },
    //       {
    //         x: "bicycle",
    //         y: 257,
    //       },
    //       {
    //         x: "horse",
    //         y: 146,
    //       },
    //       {
    //         x: "skateboard",
    //         y: 261,
    //       },
    //       {
    //         x: "others",
    //         y: 214,
    //       },
    //     ],
    //   },
    //   {
    //     id: "germany",
    //     color: "hsl(327, 70%, 50%)",
    //     data: [
    //       {
    //         x: "plane",
    //         y: 92,
    //       },
    //       {
    //         x: "helicopter",
    //         y: 80,
    //       },
    //       {
    //         x: "boat",
    //         y: 282,
    //       },
    //       {
    //         x: "train",
    //         y: 238,
    //       },
    //       {
    //         x: "subway",
    //         y: 206,
    //       },
    //       {
    //         x: "bus",
    //         y: 298,
    //       },
    //       {
    //         x: "car",
    //         y: 85,
    //       },
    //       {
    //         x: "moto",
    //         y: 197,
    //       },
    //       {
    //         x: "bicycle",
    //         y: 148,
    //       },
    //       {
    //         x: "horse",
    //         y: 182,
    //       },
    //       {
    //         x: "skateboard",
    //         y: 237,
    //       },
    //       {
    //         x: "others",
    //         y: 1,
    //       },
    //     ],
    //   },
    //   {
    //     id: "norway",
    //     color: "hsl(200, 70%, 50%)",
    //     data: [
    //       {
    //         x: "plane",
    //         y: 142,
    //       },
    //       {
    //         x: "helicopter",
    //         y: 239,
    //       },
    //       {
    //         x: "boat",
    //         y: 109,
    //       },
    //       {
    //         x: "train",
    //         y: 264,
    //       },
    //       {
    //         x: "subway",
    //         y: 16,
    //       },
    //       {
    //         x: "bus",
    //         y: 154,
    //       },
    //       {
    //         x: "car",
    //         y: 281,
    //       },
    //       {
    //         x: "moto",
    //         y: 242,
    //       },
    //       {
    //         x: "bicycle",
    //         y: 124,
    //       },
    //       {
    //         x: "horse",
    //         y: 299,
    //       },
    //       {
    //         x: "skateboard",
    //         y: 216,
    //       },
    //       {
    //         x: "others",
    //         y: 110,
    //       },
    //     ],
    //   },
    // ];

    // const p_chartData2 = [
    //   {
    //     id: "Happy 777 Sales",
    //     data: [
    //       {
    //         x: "Happy 777 Sales",
    //         y: 1463.58,
    //       },
    //     ],
    //   },
    //   {
    //     id: "Super Sunday 666 a",
    //     data: [
    //       {
    //         x: "Super Sunday 666 a",
    //         y: 379.3,
    //       },
    //     ],
    //   },
    //   {
    //     id: "Super Sunday 666 b",
    //     data: [
    //       {
    //         x: "Super Sunday 666 b",
    //         y: 73,
    //       },
    //     ],
    //   },
    //   {
    //     id: "Super Sunday 666 c",
    //     data: [
    //       {
    //         x: "Super Sunday 666 c",
    //         y: 200,
    //       },
    //     ],
    //   },
    //   {
    //     id: "Super Sunday 666 d",
    //     data: [
    //       {
    //         x: "Super Sunday 666 d",
    //         y: 200,
    //       },
    //     ],
    //   },
    //   {
    //     id: "Super Sunday 666 e",
    //     data: [
    //       {
    //         x: "Super Sunday 666 e",
    //         y: 200,
    //       },
    //     ],
    //   },
    //   {
    //     id: "sales 888",
    //     data: [
    //       {
    //         x: "sales 888",
    //         y: 44.39,
    //       },
    //     ],
    //   },
    // ];
    return (
      <div>
        {console.log("p2_Data", this.props.p2_Data)}
        {console.log("p_chartData", this.props.p_chartData)}

        <style type="text/css" media="print">
          {pageStyle()}
        </style>
        <HeaderTitle />

        {/* <Chart_Line p_dbData={nivo_dbData} /> */}
        {/* <Chart_Line p_dbData={p_chartData2} /> */}
        <Chart_Line p_dbData={this.props.p_chartData} />

        <div>
          {/* {console.log("props.p_Data", this.props.p_Data)} */}
          {/* {this.props.p_Data[0]?.paid_date} */}
          <table className="pr__table">
            <tr>
              <th className="pr__th">#</th>
              <th className="pr__th">State</th>
              <th className="pr__th">City</th>
              <th className="pr__th">Referrer ID</th>
              <th className="pr__th">Item ID</th>
              <th className="pr__th">Total Item Qty</th>
              <th className="pr__th">Total Paid RM</th>
            </tr>
            {this.props.p2_Data?.map((x, index) => (
              <>
                <tr>
                  <td className="pr__td">{index + 1}</td>
                  <td className="pr__td">{x.client_state}</td>
                  <td className="pr__td">{x.client_city}</td>
                  <td className="pr__td">{x.referer_id}</td>
                  <td className="pr__td">{x.item_id}</td>
                  <td className="pr__td">{x.total_selected_ItemQty}</td>
                  <td className="pr__td">
                    {parseFloat(x.total_payment_total_amount_paid).toFixed(2)}
                  </td>
                </tr>
                {/* <div>{index % 2}</div> */}
                <div>
                  {(index > 0 && index % 2) == 0 ? (
                    <div className="page-break" />
                  ) : (
                    <div />
                  )}
                </div>
              </>
            ))}

            <tr>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td>GRAND TOTAL</td>
              <td>
                {parseFloat(
                  this.props.p2_Data?.reduce(
                    (acc, x) => x.total_selected_ItemQty + acc,
                    0
                  )
                ).toFixed(2)}
              </td>
              <td>
                {parseFloat(
                  this.props.p2_Data?.reduce(
                    (acc, x) => x.total_payment_total_amount_paid + acc,
                    0
                  )
                ).toFixed(2)}
              </td>
            </tr>
          </table>
        </div>
        <div className="page-break" />
        {/* <div>
          <div>My cool content here! p2</div>
        </div>
        <div className="page-break" />
        <div>My cool content here! p3</div>
        <div className="page-break" />

        <div>My cool content here! p4</div> */}
      </div>
    );
  }
}

export default X;
