import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import moment from "moment";

import COM_AutoComplete_Referer from "../Component/COM_AutoComplete_Referer";
import Chart_Pie from "../Chart/Chart_Pie";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_Test() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // -------------------------- Global variable
  const [AC_SelectedData_Referer, setAC_SelectedData_Referer] = useState([]);

  const DataChart = [
    {
      id: "make",
      label: "make",
      value: 505,
      color: "#4265ff",
    },
    {
      id: "go",
      label: "go",
      value: 214,
      color: "#40f5c1",
    },
    {
      id: "erlang",
      label: "erlang",
      value: 480,
      color: "#f0ca43",
    },
    {
      id: "css",
      label: "css",
      value: 38,
      color: "#f07046",
    },
    {
      id: "c",
      label: "c",
      value: 485,
      color: "#a847ed",
    },
  ];

  useEffect(() => {}, []);

  return (
    <>
      <Chart_Pie
        dbData={DataChart}
        // title_X="2021 Brand - Items Report "
        // title_Y="Qty"
        // where_DateLike="2021"
        // sumLike="item_qty"
        // newKeyListState={newKeyListState}
        // groupMode={"stacked"} //stacked ? grouped ?
        // layout={"horizontal"} //vertical ?  horizontal ?
      />
    </>
  );
}
