import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import COM_AutoComplete_Referer from "../Component/COM_AutoComplete_Referer";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog({
  setAC_SelectedData_Client,
  C_Title,
}) {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  //
  const [DB_ClientList, setDB_ClientList] = useState([]);
  const get_DB_Client_List = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__client_info__all`)
      .then((res) => {
        setDB_ClientList(res.data);
        console.log("get_DB_Client_List", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const add_DB_Client_List = (
    _client_name,
    _client_contact,
    _client_address,

    _client_referer_id,
    _client_referer_name,
    _client_referer_role,

    _client_gen_by,
    _client_gen_uid,
    _client_gen_pic
  ) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/add_vlerp__client_info`, {
        client_name: _client_name,
        client_contact: _client_contact,
        client_address: _client_address,

        client_referer_id: _client_referer_id,
        client_referer_name: _client_referer_name,
        client_referer_role: _client_referer_role,

        client_gen_by: _client_gen_by,
        client_gen_uid: _client_gen_uid,
        client_gen_pic: _client_gen_pic,
      })
      .then((res) => {
        setDB_ClientList(res.data);
        console.log("add_DB_Client_List", res.data);
        if (res.data.affectedRows > 0) {
          alert("New Client Add Successfully ! ");

          setTimeout(() => {
            get_DB_Client_List();
            handleClose();
          }, 2000);
        } else {
          alert("Failed to Add New Client, Please contact Admin !");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // ------------------------------------------------- etc

  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      client_name: "",
      client_contact: "",
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    client_name: "",
    client_contact: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      client_id: selected_ClientID.current,
      client_name: dialogValue.client_name,
      client_contact: dialogValue.client_contact,
      client_address: dialogValue.client_address,
      client_referer: dialogValue.client_referer,
    });

    // // update parent
    setAC_SelectedData_Client({
      client_name: dialogValue.client_name,
      client_contact: dialogValue.client_contact,
      client_address: "dialogValue.client_address",
      client_referer: dialogValue.client_referer,
    });

    // add to db
    add_DB_Client_List(
      dialogValue.client_name,
      dialogValue.client_contact,
      dialogValue.client_address,

      AC_SelectedData_Referer.id,
      AC_SelectedData_Referer.referer_name,
      AC_SelectedData_Referer.referer_role,

      contextValue.current.firebase_userInfo.displayName,
      contextValue.current.firebase_userInfo.providerData[0]?.providerId,
      contextValue.current.firebase_userInfo.providerData[0]?.photoURL
    );
  };

  // ------------------------------------------------- Global Variable
  const selected_ClientID = useRef("NA");

  const [AC_SelectedData_Referer, setAC_SelectedData_Referer] = useState([]);

  // ------------------------------------------------- useEffect

  useEffect(() => {
    get_DB_Client_List();
  }, []);

  return (
    <React.Fragment>
      {console.log("AC_SelectedData_Referer", AC_SelectedData_Referer)}
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // press enter

            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                client_name: newValue,
                client_contact: "",
              });
              setAC_SelectedData_Client({
                client_name: newValue,
                client_contact: "",
              });
            });
          } else if (newValue && newValue.inputValue) {
            // New
            toggleOpen(true);
            setDialogValue({
              client_name: newValue.inputValue,
              client_contact: "",
            });
            setAC_SelectedData_Client({
              client_name: newValue.inputValue,
              client_contact: "",
            });
          } else {
            setValue(newValue);
            setAC_SelectedData_Client(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              client_name: `Add "${params?.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={DB_ClientList}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          // console.log("option", option.id);
          selected_ClientID.current = option.id;
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.id == undefined
            ? option.client_name
            : "(" + option.id + ") " + option.client_name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props}>
            <div>
              <h4>{`(${option?.id}) ${option?.client_name}`}</h4>
              <div>{`Contact : ${option?.client_contact}`}</div>
              <div>{`Address : ${option?.client_address}`}</div>
              <div>
                {`Referer : [Ref ID: ${option?.client_referer_id} ]( ${option?.client_referer_role} )  ${option?.client_referer_name}`}
              </div>
            </div>
          </li>
        )}
        sx={{ width: "100%" }}
        freeSolo
        renderInput={(params) => <TextField {...params} label={C_Title} />}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        // fullScreen
        fullWidth={true}
        maxWidth="xl"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new Client</DialogTitle>
          <DialogContent>
            <DialogContentText>..</DialogContentText>
            {console.log("dialogValue", dialogValue)}
            <div className="global_flex_column_cc gap_50 ">
              <TextField
                fullWidth
                autoFocus
                margin="dense"
                id="name"
                value={dialogValue.client_name}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    client_name: event.target.value,
                  })
                }
                label="Client Name"
                type="text"
                variant="standard"
              />
              <TextField
                fullWidth
                margin="dense"
                id="name"
                value={dialogValue.client_contact}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    client_contact: event.target.value,
                  })
                }
                label="Client Contact"
                type="number"
                variant="standard"
              />
              <TextField
                fullWidth
                margin="dense"
                id="name"
                value={dialogValue.client_address}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    client_address: event.target.value,
                  })
                }
                label="Client Addtess"
                type="text"
                variant="standard"
              />
              {/* <TextField
                fullWidth
                margin="dense"
                id="name"
                value={dialogValue.client_referer}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    client_referer: event.target.value,
                  })
                }
                label="Client Referer"
                type="text"
                variant="standard"
              /> */}
              <COM_AutoComplete_Referer
                C_Title="Select Referer"
                setAC_SelectedData_Referer={setAC_SelectedData_Referer}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add New Client</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const sampleData = [
  { title: "The Shawshank Redemption", client_contact: 1994 },
  { title: "The Godfather", client_contact: 1972 },
];
