import { UserContext } from "./UserContext";
import { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";

// ------------------------------------------------ router
import {
  HashRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";

import Page_404 from "./Page/Page_404";
import Page_Test from "./Page/Page_Test";

import Page_Login from "./Page/Page_Login";
import Page_Main from "./Page/Page_Main";
import Page_UserProfile from "./Page/Page_UserProfile";

import Page_CreateOrder from "./Page/Page_CreateOrder";

import Page_ManageOrders from "./Page/Page_ManageOrders";
import Page_Order_CreateOrder from "./Page/Page_Order_CreateOrder";
import Page_Order_CreateOrder_Template from "./Page/Page_Order_CreateOrder_Template";
import Page_Order_1_PrepareOrder from "./Page/Page_Order_1_PrepareOrder.js.js";
import Page_Order_2_ReadyTo3PL from "./Page/Page_Order_2_ReadyTo3PL";
import Page_Order_3_OrderComplete from "./Page/Page_Order_3_OrderComplete";

import Page_ManageReports from "./Page/Page_ManageReports";
import Print_Report_Sales_Monthly from "./Print/Print_Report_Sales_Monthly";
import Print_Report_Sales_Custom from "./Print/Print_Report_Sales_Custom";

import Page_ManageStocks from "./Page/Page_ManageStocks";
import Page_ManageStocks_Item from "./Page/Page_ManageStocks_Item";

import Page_ManageUsers from "./Page/Page_ManageUsers";
import Page_ManageUsers_SystemUser from "./Page/Page_ManageUsers_SystemUser";
import Page_ManageUsers_Referer from "./Page/Page_ManageUsers_Referer";

import "./Css/CSS_Page_Login.css";
import "./Css/CSS_Page_Order_1a_CreateOrder.css";
import "./Css/CSS_Page_Order_1b_CreateOrder_Template.css";
import "./Css/CSS_Global.css";
import "./Css/CSS_NavBar.css";
import "./Css/CSS_PrintReport.css";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

const parrent_DB_userinfo = [];
const fakeAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

function ProtectedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return fakeAuth.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
}

function App() {
  // --------------------------------------- use context
  const contextValue = useRef("NA");

  // get version
  const getVersion = async () => {
    const x = await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_vlerp__version`)
      .then((res) => {
        if (localStorage.getItem("ver") === null) {
          localStorage.setItem("ver", res.data[0].value);

          window.location.reload();
        }
        if (localStorage.getItem("ver") !== res.data[0].value) {
          localStorage.setItem("ver", res.data[0].value);
          alert("New Update Available ! " + res.data[0].value);

          window.location.reload();
        }
      });
  };

  useEffect(() => {
    getVersion();
  }, []);

  return (
    <>
      <UserContext.Provider value={{ contextValue }}>
        <HashRouter hashType="slash">
          <Switch>
            <Route
              exact
              path="/"
              component={() => (
                <Page_Login
                  parent_fakeAuth={fakeAuth}
                  parrent_DB_userinfo={parrent_DB_userinfo}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/main"
              component={() => (
                <Page_Main parrent_DB_userinfo={parrent_DB_userinfo} />
                // <Page_Order_1b_CreateOrder_Template
                //   parrent_DB_userinfo={parrent_DB_userinfo}
                // />
              )}
            />
            <ProtectedRoute
              exact
              path="/userprofile"
              component={Page_UserProfile}
            />

            <ProtectedRoute
              exact
              path="/Page_CreateOrder"
              component={Page_CreateOrder}
            />
            <ProtectedRoute
              exact
              path="/Page_ManageOrders"
              component={Page_ManageOrders}
            />
            <ProtectedRoute
              exact
              path="/Page_Order_CreateOrder"
              component={Page_Order_CreateOrder}
            />
            <ProtectedRoute
              exact
              path="/Page_Order_CreateOrder_Template"
              component={Page_Order_CreateOrder_Template}
            />
            <ProtectedRoute
              exact
              path="/Page_Order_1_PrepareOrder"
              component={Page_Order_1_PrepareOrder}
            />
            <ProtectedRoute
              exact
              path="/Page_Order_2_ReadyTo3PL"
              component={Page_Order_2_ReadyTo3PL}
            />
            <ProtectedRoute
              exact
              path="/Page_Order_3_OrderComplete"
              component={Page_Order_3_OrderComplete}
            />
            <ProtectedRoute
              exact
              path="/Page_ManageReports"
              component={Page_ManageReports}
            />
            <ProtectedRoute
              exact
              path="/Print_Report_Sales_Monthly"
              component={Print_Report_Sales_Monthly}
            />
            <ProtectedRoute
              exact
              path="/Print_Report_Sales_Custom"
              component={Print_Report_Sales_Custom}
            />
            <ProtectedRoute
              exact
              path="/Page_ManageStocks"
              component={Page_ManageStocks}
            />
            <ProtectedRoute
              exact
              path="/Page_ManageStocks_Item"
              component={Page_ManageStocks_Item}
            />
            <ProtectedRoute
              exact
              path="/Page_ManageUsers"
              component={Page_ManageUsers}
            />
            <ProtectedRoute
              exact
              path="/Page_ManageUsers_SystemUser"
              component={Page_ManageUsers_SystemUser}
            />
            <ProtectedRoute
              exact
              path="/Page_ManageUsers_Referer"
              component={Page_ManageUsers_Referer}
            />

            <Route exact path="/Page_Test" component={Page_Test} />

            <Route exact path="*" component={Page_404} />
          </Switch>
        </HashRouter>
      </UserContext.Provider>
    </>
  );
}

export default App;
