import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog({
  setAC_SelectedData_Referer,
  C_Title,
}) {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  //
  const [DB_RefererList, setDB_RefererList] = useState([]);
  const get_DB_Referer_List = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__referer_info__all`)
      .then((res) => {
        setDB_RefererList(res.data);
        console.log("get_DB_Referer_List", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const [DB_Users, setDB_Users] = useState([]);
  const get_DB_Users = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__users__all`)
      .then((res) => {
        setDB_Users(res.data);
        console.log("get_DB_Users", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const add_DB_Referer_List = (
    _referer_name,
    _referer_role,

    _referer_contact,
    _referer_address,
    _referer_gen_by,
    _referer_gen_uid,
    _referer_gen_pic
  ) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/add_vlerp__referer_info`, {
        referer_name: String(_referer_name).replace("'", "`"),
        referer_role: String("Et 9999").replace("'", "`"),

        referer_contact: String(_referer_contact).replace("'", "`"),
        referer_address: String(_referer_address).replace("'", "`"),
        referer_gen_by: String(_referer_gen_by).replace("'", "`"),
        referer_gen_uid: String(_referer_gen_uid),
        referer_gen_pic: String(_referer_gen_pic),
      })
      .then((res) => {
        setDB_RefererList(res.data);
        console.log("add_DB_Referer_List", res.data);
        if (res.data.affectedRows > 0) {
          alert("New Referer Add Successfully ! ");

          setTimeout(() => {
            get_DB_Referer_List();
            handleClose();
          }, 2000);
        } else {
          alert("Failed to Add New Referer, Please contact Admin !");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // ------------------------------------------------- etc

  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      referer_name: "",
      referer_contact: "",
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    referer_name: "",
    referer_contact: "",
  });

  const handleSubmit = (event) => {
    console.log("hit");
    event.preventDefault();
    setValue({
      referer_id: selected_RefererID.current,
      referer_name: dialogValue.referer_name,
      referer_contact: dialogValue.referer_contact,
      referer_address: dialogValue.referer_address,
    });

    // // update parent
    setAC_SelectedData_Referer({
      referer_name: dialogValue.referer_name,
      referer_contact: dialogValue.referer_contact,
      referer_address: "dialogValue.referer_address",
    });

    // add to db
    add_DB_Referer_List(
      dialogValue.referer_name,
      Selected_RoleValue,

      dialogValue.referer_contact,
      dialogValue.referer_address,
      contextValue.current.firebase_userInfo.displayName,
      contextValue.current.firebase_userInfo.uid,
      contextValue.current.firebase_userInfo.providerData[0]?.photoURL
    );
  };

  // ------------------------------------------------- Global Variable
  const selected_RefererID = useRef("NA");

  // ------------------------------------------------- Radio

  const [Selected_RoleValue, setSelected_RoleValue] = React.useState("CS");

  const hc_Selected_RoleValue = (event) => {
    setSelected_RoleValue(event.target.value);
  };

  // ------------------------------------------------- cust component
  // const [refererOwnners, setRefererOwnners] = useState([]);
  const findRefererOwnners = (_systemUserId) => {
    // console.log("findRefererOwnner", _systemUserId);
    let refererOwnners = DB_Users.filter((x) => {
      let result = [];
      if (
        x.u_referer_list != null &&
        x.u_referer_list != undefined &&
        x.u_referer_list != ""
      ) {
        result = JSON.parse(x.u_referer_list.toString());
      }

      return result.includes(_systemUserId.toString());
    });

    // console.log("refererOwnners", refererOwnners);
    return refererOwnners;
    // setRefererOwnners(refererOwnners);
  };

  // ------------------------------------------------- useEffect

  useEffect(() => {
    get_DB_Referer_List();
    get_DB_Users();
  }, []);

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                referer_name: newValue,
                referer_contact: "",
              });
              setAC_SelectedData_Referer({
                referer_name: newValue,
                referer_contact: "",
              });
            });
          } else if (newValue && newValue.inputValue) {
            // New
            toggleOpen(true);
            setDialogValue({
              referer_name: newValue.inputValue,
              referer_contact: "",
            });
            setAC_SelectedData_Referer({
              referer_name: newValue.inputValue,
              referer_contact: "",
            });
          } else {
            if (newValue == null) {
              // fix reset text cause crash
              setAC_SelectedData_Referer([]); // because parent is array
              setValue(newValue);
            } else {
              setAC_SelectedData_Referer(newValue);
              setValue(newValue);
            }
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          //---------------------------------------------- Add new value func
          // if (params.inputValue !== "") {
          //   filtered.push({
          //     inputValue: params.inputValue,
          //     referer_name: `Add "${params?.inputValue}"`,
          //   });
          // }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={DB_RefererList}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          // console.log("option", option.id);
          selected_RefererID.current = option.id;
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.id == undefined
            ? option.referer_name
            : "(" + option.id + ") " + option.referer_name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props}>
            <div className="global_grid_container_custom002">
              <div className="global_flex_row_cc gap_50">
                <div className="global_padding_left width_20rem">
                  <b>{`(${option?.id}) ${option?.referer_name}`}</b>
                  <div>{`Contact : ${option?.referer_contact}`}</div>
                </div>
                {/* {console.log("option", option)} */}
                <div>
                  {findRefererOwnners(
                    option?.id != null && option?.id != undefined
                      ? option?.id
                      : ""
                  )?.map((qq) => (
                    <div className="global_flex_row_cc_t2 margin_20">
                      <img
                        // style={{ width: "50px", borderRadius: "50px" }}
                        className="global_profile_pic_small"
                        src={`${JSON.parse(qq?.u_firebase_obj)?.photoURL}`}
                      />
                      <div className="margin_20">{`[${
                        qq?.id
                      }] (${qq?.u_role?.replaceAll("_", " ")}) ${
                        qq?.u_name
                      }`}</div>
                      <div></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </li>
        )}
        sx={{ width: "100%" }}
        freeSolo
        renderInput={(params) => <TextField {...params} label={C_Title} />}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        // fullScreen
        fullWidth={true}
        maxWidth="xl"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new Referer</DialogTitle>
          <DialogContent>
            <DialogContentText>..</DialogContentText>

            <div className="global_flex_column_cc gap_50">
              <TextField
                fullWidth
                autoFocus
                margin="dense"
                id="name"
                value={dialogValue.referer_name}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    referer_name: event.target.value,
                  })
                }
                label="Referer Name"
                type="text"
                variant="standard"
              />

              {/* <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={Selected_RoleValue}
                onChange={hc_Selected_RoleValue}
              >
                <FormControlLabel
                  value="Admin"
                  control={<Radio />}
                  label="Lv0 : Admin"
                />
                <FormControlLabel
                  value="Brand_Partner"
                  control={<Radio />}
                  label="Lv1 : Brand Partner"
                />
                <FormControlLabel
                  value="Brand_Partner__Customer_Service"
                  control={<Radio />}
                  label="Lv2 : Brand Partner Customer Service"
                />
                <FormControlLabel
                  value="Agent"
                  control={<Radio />}
                  label="Lv3 : Agent"
                />
                <FormControlLabel
                  value="Agent_Customer__Service"
                  control={<Radio />}
                  label="Lv4 : Agent Customer Service"
                />
                <FormControlLabel
                  value="Banned_User"
                  control={<Radio />}
                  label="Banned User"
                />
              </RadioGroup> */}
              <TextField
                fullWidth
                margin="dense"
                id="name"
                value={dialogValue.referer_contact}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    referer_contact: event.target.value,
                  })
                }
                label="Referer Contact"
                type="text"
                variant="standard"
              />
              <TextField
                fullWidth
                margin="dense"
                id="name"
                value={dialogValue.referer_address}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    referer_address: event.target.value,
                  })
                }
                label="Referer Addtess"
                type="text"
                variant="standard"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const sampleData = [
  { title: "The Shawshank Redemption", referer_contact: 1994 },
  { title: "The Godfather", referer_contact: 1972 },
];
