import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function COM_NavBar({ p_Selected_Button }) {
  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  // --------------------------------------- Global variable
  const selected_Button = useRef(p_Selected_Button);

  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  return (
    <>
      {/* {console.log("p_Selected_Button", p_Selected_Button)} */}
      <div className="NavBar_Container global_flex_row_cc">
        <div
          className={
            selected_Button.current == "1"
              ? "NavBar_Btn_1_Selected"
              : "NavBar_Btn_1"
          }
          onClick={() => (window.location.href = "#/Page_CreateOrder")}
        >
          <div>1.</div>
          <div>Create Order</div>
        </div>
        <div
          className={
            selected_Button.current == "2"
              ? "NavBar_Btn_2_Selected"
              : "NavBar_Btn_2"
          }
          onClick={() => (window.location.href = "#/Page_ManageOrders ")}
        >
          <div>2.</div>
          <div>Manage Orders</div>
        </div>
        <div
          className={
            selected_Button.current == "3"
              ? "NavBar_Btn_3_Selected"
              : "NavBar_Btn_3"
          }
          onClick={() => (window.location.href = "#/Page_ManageReports")}
        >
          <div>3.</div>
          <div>Manage Reports</div>
        </div>
        <div
          className={
            selected_Button.current == "4"
              ? "NavBar_Btn_4_Selected"
              : "NavBar_Btn_4"
          }
          onClick={() => (window.location.href = "#/Page_ManageStocks")}
        >
          <div>4.</div>
          <div>Manage Stocks</div>
        </div>
        <div
          className={
            selected_Button.current == "5"
              ? "NavBar_Btn_5_Selected"
              : "NavBar_Btn_5"
          }
          onClick={() => (window.location.href = "#/Page_ManageUsers")}
        >
          <div>5.</div>
          <div>Manage Users</div>
        </div>

        <div
          className="btn_logout global_flex_column_cc "
          onClick={() => {
            signOut(authentication);
            setTimeout(() => {
              window.location.href = "/";
              alert("Signed Out Successully!");
            }, 1000);
          }}
        >
          <div className="global_flex_row_cc">
            <img
              className="img_UserProfile_small"
              src={`${firebase_userInfo?.photoURL}`}
            />
            <div className="global_flex_col_cc">
              <div>{`${contextValue?.userInfo?.u_role} | lv${contextValue?.userInfo?.u_role_lvl} | ${contextValue?.userInfo?.u_name} `}</div>
              <div className="global_flex_row_cc">
                {contextValue?.userInfo?.u_referer_list != ""
                  ? JSON.parse(contextValue?.userInfo?.u_referer_list)?.map(
                      (x) => <div>{x},</div>
                    )
                  : []}
              </div>
            </div>
          </div>

          <div>LOGOUT</div>
        </div>
      </div>
    </>
  );
}
