import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";
import COM_NavBar_User from "../Component/COM_NavBar_User";

import Print_Report_Sales_Monthly from "../Print/Print_Report_Sales_Monthly";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_SalesReports() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  return (
    <>
      <COM_NavBar_Global p_Selected_Button="5" />
      <COM_NavBar_User
      // p_Selected_Button="1"
      />
      <h1>5. Manage Users</h1>
      <br />
      <br />
      <br />
    </>
  );
}
