// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import React, { Component, useState, useReducer, useEffect } from "react";
import axios from "axios";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";

import {
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
} from "@material-ui/core";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default function ThisApp({
  title_X,
  title_Y,
  select_DateLike,
  where_DateLike,
  sumLike,
  newKeyListState,
  p_dbData, // main
  p_dbData_Fill, // optional
  groupMode,
  layout,
}) {
  // const [checked, setChecked] = React.useState(true);

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  return (
    <>
      <div
        style={{
          height: "800px",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <ResponsivePie
          data={p_dbData}
          sortByValue={true}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          startAngle={0}
          innerRadius={0.25}
          padAngle={5}
          cornerRadius={6}
          activeOuterRadiusOffset={8}
          colors={{ scheme: "nivo" }}
          // colors={{ datum: "data.color" }} // <-- Custom Color from Data Obj
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={p_dbData_Fill}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
}
