import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import moment from "moment";

import Page_Order_1b_CreateOrder_Template__Create from "./Page_Order_CreateOrder_Template__Create";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";

import COM_NavBar_Order from "../Component/COM_NavBar_Order";

import { AiFillEdit } from "react-icons/ai";

//  -------------------------- Material Table
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import ReplayIcon from "@mui/icons-material/Replay";

import {
  TextField,
  FormGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB
  // get data
  const [DB_Data_All, setDB_Data_All] = useState([]);
  const get_DB_Data_All = () => {
    console.log("112aa");
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__order_template__all`)
      .then((res) => {
        console.log("1122", res);

        setDB_Data_All(res.data);
        console.log("# get_DB_Data_All", res.data);
      });
  };

  // get data - by order id
  const [DB_Data_byorderid, setDB_Data_byorderid] = useState([]);
  const get_DB_Data_byorderid = (_tp_order_id) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__order_template__byid`, {
        tp_order_id: _tp_order_id,
      })
      .then((res) => {
        setDB_Data_byorderid(res.data);
        console.log("# get_DB_Data_byorderid", res.data);
      });
  };

  // ------------------------------------------------- Table Columns
  const columns = [
    {
      title: "Order Template Id",
      field: "tp_order_id",
      width: "1px",
      cellStyle: {
        backgroundColor: "rgb(250, 169, 94)",
        color: "#000",
      },
      headerStyle: {
        backgroundColor: "rgb(250, 169, 94)",
        color: "#000",
        minWidth: "100px",
      },
    },
    {
      title: "Total Item",
      field: "Total_Item",
      width: "1px",
    },
    {
      title: "Order Template Name",
      field: "tp_name",
      minWidth: "150px",
    },
    {
      title: "From",
      field: "tp_dt_from",
      minWidth: "120px",
    },
    {
      title: "Until",
      field: "tp_dt_until",
      minWidth: "120px",
    },
    {
      title: "Duration Remain",
      field: "DurationRemain",
      width: "1px",
    },
    {
      title: "Referer id",
      field: "referer_id",
      minWidth: "1px",
    },
    {
      title: "Referer Role",
      field: "referer_role",
      minWidth: "1px",
    },
    {
      title: "Referer Name",
      field: "referer_name",
      minWidth: "120px",
    },

    {
      title: "Generate at",
      field: "tp_gen_dt",
      minWidth: "120px",
    },
    {
      title: "Gen By",
      field: "step1_by",
      minWidth: "1px",
      render: (rowData) => (
        <>
          <div className="global_flex_row_cc">
            <img
              className="global_profile_pic_small"
              src={rowData.tp_gen_by_pic}
            />
            <div className="padding_10">{rowData.tp_gen_by}</div>
          </div>
        </>
      ),
    },
  ];
  const columns_DB_Data_byorderid = [
    {
      title: "sid",
      field: "id",
      maxWidth: "1px",
    },
    {
      title: "Item Id",
      field: "item_id",
      maxWidth: "1px",
    },
    {
      title: "Item Name",
      field: "item_name",
      maxWidth: "300px",
    },

    {
      title: "Item Price Cost",
      field: "item_price_cost",
      width: "1px",
    },
    {
      title: "Item Price SRP",
      field: "item_price_srp",
      width: "1px",
    },
    {
      title: "Item Price Price Per Unit",
      field: "selected_Item_PricePerUnit",
      width: "1px",
    },
    {
      title: "Item Min Order Quantity",
      field: "selected_Item_MinOrderQty",
      width: "1px",
    },
  ];

  // --------------------------------------- dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const hd_DialogOpen = () => {
    setDialogOpen(true);
  };

  const hd_DialogClose = () => {
    setDialogOpen(false);
  };

  const [dialog2Open, setDialog2Open] = useState(false);
  const hd_Dialog2Open = () => {
    setDialog2Open(true);
  };

  const hd_Dialog2Close = () => {
    setDialog2Open(false);
  };

  // -------------------------- Global variable

  const selected_Order_Id = useRef("NA");

  // ---------------------------------- useEffect
  useEffect(() => {
    get_DB_Data_All();
  }, []);

  return (
    <>
      <br />
      <br />
      <br />
      <h1>Create Order Template</h1>
      <br />
      <div
        className="btn_normal"
        onClick={() => {
          hd_Dialog2Open();
        }}
      >
        <div className="padding_30">Create New Order Template</div>
      </div>
      <br />
      <MaterialTable
        style={{
          MuiTablePaginationRoot: "gold",
          color: "black",
          // background: "rgba(0, 0, 100, .0)",
          backgroundImage:
            "linear-gradient(to bottom right, rgb(255, 255, 255), rgb(255, 255, 255))",
          boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
          backdropFilter: "blur( 11.0px )",
          "&::webkit-backdrop-filter": {
            webkitBackdropFilter: "blur( 18.0px )",
          },
        }}
        title={
          <div className="global_flex_row_cc">
            <h1>Order Template</h1>
            <Button
              // variant="outlined"
              onClick={() => {
                get_DB_Data_All("yes");
              }}
            >
              <ReplayIcon />
              Reload Data
            </Button>
            {/* <Button
              // variant="outlined"
              onClick={() => {
                get_DB_Data_All("");
              }}
            >
              Show All Data
            </Button> */}
          </div>
        }
        columns={columns}
        data={DB_Data_All}
        options={{
          headerStyle: {
            background: "rgba(255, 255, 255, .2)",
            border: "none",
          },
          exportButton: true,
          rowStyle: (rowData) => ({
            // backgroundColor: "#1a0000",
            // color: "#ffffff",
            fontSize: 12,
          }),
          filtering: true,
          // grouping: true,
          // exportAllData: true,
          pageSize: 10,
          pageSizeOptions: [20, 50, 100],
          paginationType: "stepped",
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
          // exportMenu: [
          //   {
          //     label: "Export PDF",
          //     exportFunc: (cols, datas) =>
          //       ExportPdf(cols, datas, "myPdfFileName"),
          //   },
          //   {
          //     label: "Export CSV",
          //     exportFunc: (cols, datas) =>
          //       ExportCsv(cols, datas, "myCsvFileName"),
          //   },
          // ],
        }}
        onRowClick={(evt, selectedRow) => {
          //
        }}
        actions={[
          {
            icon: "save",
            tooltip: "Save User",
            onClick: (event, selectedRow) => {
              // alert(selectedRow.order_id);
              selected_Order_Id.current = selectedRow.tp_order_id;
              get_DB_Data_byorderid(selectedRow.tp_order_id);
              setTimeout(() => {
                hd_DialogOpen();
              }, 1000);
            },
          },
        ]}
        components={{
          Action: (props) => (
            <>
              <div
                className="btn_edit "
                onClick={(event) => props.action.onClick(event, props.data)}
              >
                <AiFillEdit style={{ transform: "scale(2)" }} />
              </div>
            </>
          ),
        }}
      />
      <Dialog
        // onBackdropClick="false"
        // disableEscapeKeyDown
        fullWidth={true}
        maxWidth="xl"
        fullScreen
        open={dialogOpen}
        onClose={hd_DialogClose}
        style={
          {
            // background: "rgba(255, 195, 113, 1)",
            // backdropFilter: "blur( 5.0px )",
          }
        }
        PaperProps={{
          style: {
            // background: "rgba(255, 195, 113, .0)",
            // boxShadow: "0 0",
          },
        }}
      >
        <DialogTitle>
          {`Order Template Id : ${selected_Order_Id.current}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <div className="global_flex_row_cc">
            <TextField
              disabled
              fullWidth
              autoFocus
              margin="dense"
              id="name"
              value={`https://vlc.eclipsemy.com/#/order/${DB_Data_byorderid[0]?.uuid}`}
              onChange={(event) => {
                // (selected_TrackingNo.current = event.target.value)
                // setSelected_UUID(event.target.value);
              }}
              label="Share Link to Client"
              type="text"
              variant="standard"
            />

            <div
              className="btn_normal"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://vlc.eclipsemy.com/#/order/${DB_Data_byorderid[0]?.uuid}`
                );
              }}
            >
              Copy URL
            </div>
            <div
              className="btn_normal"
              onClick={() => {
                window.open(
                  `https://vlc.eclipsemy.com/#/order/${DB_Data_byorderid[0]?.uuid}`
                );
              }}
            >
              Open URL
            </div>
            <br />
          </div>
          <div className="global_grid_2column_container_2080">
            <h3>Order Template :</h3>
            <h3>{`[${DB_Data_byorderid[0]?.tp_order_id}] ${DB_Data_byorderid[0]?.tp_name}`}</h3>
            <h3>Promotion Date :</h3>
            <div>
              <h3>{`${DB_Data_byorderid[0]?.tp_dt_from} - ${DB_Data_byorderid[0]?.tp_dt_until}`}</h3>
              <div>{`${DB_Data_byorderid[0]?.DurationRemain}`} </div>
            </div>

            <h3>Referer Name :</h3>
            <h3>
              {`[${DB_Data_byorderid[0]?.referer_id}](${DB_Data_byorderid[0]?.referer_role}) ${DB_Data_byorderid[0]?.referer_name}`}{" "}
            </h3>

            <h3>Courier Fees :</h3>
            <h3>
              {`${DB_Data_byorderid[0]?.courier_region} - RM ${DB_Data_byorderid[0]?.courier_fee}`}
            </h3>
          </div>

          <MaterialTable
            style={{
              MuiTablePaginationRoot: "gold",
              color: "black",
              // background: "rgba(0, 0, 100, .0)",
              backgroundImage:
                "linear-gradient(to bottom right, rgb(255, 255, 255), rgb(255, 255, 255))",
              boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
              backdropFilter: "blur( 11.0px )",
              "&::webkit-backdrop-filter": {
                webkitBackdropFilter: "blur( 18.0px )",
              },
            }}
            title="Order Template - Details "
            columns={columns_DB_Data_byorderid}
            data={DB_Data_byorderid}
            options={{
              headerStyle: {
                background: "rgba(255, 255, 255, .2)",
                border: "none",
              },
              exportButton: true,
              rowStyle: (rowData) => ({
                // backgroundColor: "#1a0000",
                // color: "#ffffff",
                fontSize: 12,
              }),
              filtering: true,
              // grouping: true,
              // exportAllData: true,
              pageSize: 5,
              pageSizeOptions: [10, 20, 50, 100],
              paginationType: "stepped",
              // actionsColumnIndex: -1
              // fixedColumns: {
              //     left: 1,
              // }
              // exportMenu: [
              //   {
              //     label: "Export PDF",
              //     exportFunc: (cols, datas) =>
              //       ExportPdf(cols, datas, "myPdfFileName"),
              //   },
              //   {
              //     label: "Export CSV",
              //     exportFunc: (cols, datas) =>
              //       ExportCsv(cols, datas, "myCsvFileName"),
              //   },
              // ],
            }}
            onRowClick={(evt, selectedRow) => {
              // alert(selectedRow.order_id);
              selected_Order_Id.current = selectedRow.order_id;
              hd_DialogOpen();
            }}
            // actions={[
            //   {
            //     icon: "save",
            //     tooltip: "Save User",
            //     onClick: (event, rowData) => {
            //       // handleClickOpen_1_2();
            //       // getDB_patroninfo_byid(rowData.id);
            //       // alert(rowData.id);
            //     },
            //   },
            // ]}
            components={{
              Action: (props) => (
                <>
                  {/* <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "gold" }}>DONE</div>
              </Button>
              <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "#2bc3ff" }}>EDIT</div>
              </Button> */}
                </>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <div
            className="btn_normal"
            onClick={() => {
              hd_DialogClose();
            }}
          >
            Close
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        // onBackdropClick="false"
        // disableEscapeKeyDown
        fullWidth={true}
        maxWidth="xl"
        fullScreen
        open={dialog2Open}
        onClose={hd_Dialog2Close}
        style={
          {
            // background: "rgba(255, 195, 113, 1)",
            // backdropFilter: "blur( 5.0px )",
          }
        }
        PaperProps={{
          style: {
            // background: "rgba(255, 195, 113, .0)",
            // boxShadow: "0 0",
          },
        }}
      >
        <DialogTitle>Create New Template</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Page_Order_1b_CreateOrder_Template__Create
            hd_DialogClose={hd_Dialog2Close}
            db_reload={get_DB_Data_All}
          />
        </DialogContent>
        <DialogActions>
          <div
            className="btn_normal"
            onClick={() => {
              hd_Dialog2Close();
            }}
          >
            Close
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
